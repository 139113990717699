import { Card } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: top;
`;

export const FormContainer = styled(Card)`
    padding: 25px;
    display: flex;
    flex-direction: column;
    margin: 5px;
    row-gap: 20px;
`;
