import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Container } from "./styles";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { useModalContext } from "../../contexts/ModalContext";
import SaleDetailsModal from "./SaleDetailsModal";
import * as SalesService from "../../services/sales";
import { useAuth } from "../../hooks/useAuth";
import { Typography } from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import AddIcon from "@mui/icons-material/Add";
import { Chart } from "react-google-charts";
import FilterComponent from "../../components/ui/Filter";

var moment = require("moment");

const chartOptions = {
    title: "Total em Vendas " + moment().year(),
    curveType: "function",
    legend: { position: "right" },
    series: {
        0: { color: "#a18e38" },
    },
};

const Sales = () => {
    const { setModal } = useModalContext();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState([
        ["Mês", "Vendas"],
        ["Jan", 0],
    ]);
    const [amountData, setAmountData] = useState(0);
    const { authenticated, loggedMechanic } = useAuth();

    //pagination states
    const [loading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortDirection, setSortDirection] = useState("DESC");
    const [sortBy, setSortBy] = useState("date");
    const [filterText, setFilterText] = useState("");

    useEffect(() => {
        if (authenticated) fetchSales(); //fetch page 0 of sales
    }, [page, perPage, sortDirection, sortBy, filterText, loggedMechanic, authenticated]);

    const fetchSales = async () => {
        var response = await SalesService.getSalesByMechanicId(
            loggedMechanic.id,
            page,
            perPage,
            sortBy,
            sortDirection,
            filterText
        );

        const sales = response.content;

        sales.map((s) => {
            var totalPrice = 0;
            s.soldProductOrServices.map((sps) => {
                totalPrice += sps.price * sps.quantity - sps.discount * sps.quantity;
            });
            s.totalPrice = totalPrice;
        });
        setData(sales);
        setAmountData(response.totalElements);
        setTotalRows(response.totalElements);
        defineChartData(sales);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage - 1);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSortChange = async (column, sortDirection) => {
        setSortBy(column.id);
        setSortDirection(sortDirection.toUpperCase());
    };

    async function defineChartData(sales) {
        var data = [["Mês", "Vendas"]];
        var auxArr = [["Mês", "Vendas"]];
        sales.map((s) => {
            if (moment(s.date).year() === moment().year()) {
                data.push([moment(s.date).month() + 1, s.totalPrice]);
            }
        });

        for (let i = 1; i < 13; i++) {
            var label = "";
            var monthPrice = 0;
            data.map((d) => {
                if (d[0] === i) {
                    monthPrice += d[1];
                }
            });
            if (i === 1) {
                label = "Jan";
            }
            if (i === 2) {
                label = "Fev";
            }
            if (i === 3) {
                label = "Mar";
            }
            if (i === 4) {
                label = "Abr";
            }
            if (i === 5) {
                label = "Mai";
            }
            if (i === 6) {
                label = "Jun";
            }
            if (i === 7) {
                label = "Jul";
            }
            if (i === 8) {
                label = "Ago";
            }
            if (i === 9) {
                label = "Set";
            }
            if (i === 10) {
                label = "Out";
            }
            if (i === 11) {
                label = "Nov";
            }
            if (i === 12) {
                label = "Dec";
            }
            auxArr.push([label, monthPrice]);
        }
        setChartData(auxArr);
    }

    const columns = [
        {
            id: "date",
            name: "Data",
            selector: (row) => moment(row.date).local().format("DD/MM/YYYY"),
            defaultSortField: true,
            sortable: true,
        },
        {
            id: "cpf",
            name: "Cliente",
            selector: (row) => row.cpf,
        },
        {
            id: "vehicle",
            name: "Veículo",
            selector: (row) => row.vehicleLicensePlate,
        },
        {
            id: "sale",
            name: "Venda",
            selector: (row) => row.soldProductOrServices[0]?.name,
        },
        {
            id: "totalPrice",
            name: "Valor",
            selector: (row) =>
                new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                }).format(row.totalPrice),
        },
        {
            name: "Detalhes",
            cell: (row) => (
                <Button
                    id={row.ID}
                    variant="outlined"
                    onClick={() => setModal(<SaleDetailsModal data={row} updateTable={fetchSales} />)}
                >
                    <ManageSearchIcon />
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value.toUpperCase())}
                onClear={handleClear}
                filterText={filterText}
                placeholder="Filtro Placa de Veículo"
            />
        );
    }, [filterText]);

    return (
        <Container>
            <Box
                mb={1}
                fontWeight="bold"
                color="#666666"
                fontSize={{
                    xs: "h7.fontSize",
                    sm: "h6.fontSize",
                    md: "h5.fontSize",
                }}
            >
                Registros
            </Box>
            <Box
                mb={3}
                color="#666666"
                fontSize={{
                    xs: "h8.fontSize",
                }}
            >
                Registros de manutenções que aparecem no aplicativo dos seus clientes
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                {/* <Button variant="contained" onClick={() => history.push("/dashboard/registros/novo-registro")}>
                    <AddIcon fontSize="small" />
                    Novo Registro
                </Button> */}
                <Typography
                    color="#a18e38"
                    fontSize={{
                        xs: "h7.fontSize",
                        sm: "h6.fontSize",
                        md: "h6.fontSize",
                    }}
                >
                    Total: <b>{amountData} Registros</b>
                </Typography>
            </Box>
            <Box mt={3}>
                <Chart chartType="LineChart" width="100%" height="250px" data={chartData} options={chartOptions} />
            </Box>
            <Box mt={3} mb={5}>
                <Card>
                    <DataTable
                        data={data}
                        columns={columns}
                        pagination
                        sortServer
                        onSort={handleSortChange}
                        progressPending={loading}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                    />
                </Card>
            </Box>
        </Container>
    );
};

export default Sales;
