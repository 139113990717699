import React from "react";
import { Switch } from "react-router-dom";
import Clients from "../../pages/Clients";
import Sales from "../../pages/Sales";
import NewSale from "../../pages/Sales/NewSale";
import Products from "../../pages/ProductsAndServices/Products";
import Services from "../../pages/ProductsAndServices/Services";
import Audit from "../../pages/Audit";
import Users from "../../pages/Users";
import Route from "../Route";
import ChangePassword from "../../pages/Configurations/Profile/ChangePassword";
import Configurations from "../../pages/Configurations";
import Support from "../../pages/Support";
import Notifications from "../../pages/Notifications";
import Promotions from "../../pages/Promotions";

const SubRoutes = () => {
    return (
        <Switch>
            {/* never fucking puts another main component on routes of another main component, it will be fire everywhere */}
            <Route path="/dashboard/clientes" isPrivate exact component={Clients} />
            <Route path="/dashboard/registros" isPrivate exact component={Sales} />
            <Route path="/dashboard/registros/novo-registro" isPrivate exact component={NewSale} />
            <Route path="/dashboard/gerenciar/produtos" isPrivate exact component={Products} />
            <Route path="/dashboard/gerenciar/serviços" isPrivate exact component={Services} />
            <Route path="/dashboard/auditoria" exact isPrivate component={Audit} />
            <Route path="/dashboard/suporte" exact isPrivate component={Support} />
            <Route path="/dashboard/notificações" exact isPrivate component={Notifications} />
            <Route path="/dashboard/promoções" exact isPrivate component={Promotions} />
            <Route path="/dashboard/gerenciar/usuarios" isPrivate exact component={Users} />
            <Route path="/dashboard/configurações" isPrivate exact component={Configurations} />
            <Route path="/dashboard/configurações/alterar-senha" isPrivate exact component={ChangePassword} />
        </Switch>
    );
};

export default SubRoutes;
