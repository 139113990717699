import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
    ClientDetailsContainer,
    Container,
    DetailsTableContainer,
    FormContainer,
    ProductListContainer,
    AddSaleContainer,
    TableContainer,
    NotesContainer,
    StyledCurrencyInput,
} from "./styles";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useAuth } from "../../../hooks/useAuth";
import * as MechanicsService from "../../../services/mechanics";
import * as ProductsAndServicesService from "../../../services/productsAndServices";
import * as SalesService from "../../../services/sales";
import { useHistory } from "react-router-dom";
import FilterComponent from "../../../components/ui/Filter";
import NewProductServiceModal from "../../ProductsAndServices/NewProductServiceModal";
import { useModalContext } from "../../../contexts/ModalContext";

var moment = require("moment");

const NewSale = () => {
    const { loggedMechanic, userConfigs } = useAuth();
    const { setModal } = useModalContext();
    const history = useHistory();

    const [vehicles, setVehicles] = useState([]);
    const [psTableData, setPSTableData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [inputs, setInputs] = useState({
        notes: "",
        kilometers: null,
        client: "",
        clientId: "",
        clientCpf: "",
        date: moment(),
        licensePlate: "",
    });
    const [serviceFilter, setServiceFilter] = React.useState("product");
    const [tableTotalPrice, setTableTotalPrice] = useState(0);

    //pagination states
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortDirection, setSortDirection] = useState("ASC");
    const [sortBy, setSortBy] = useState("name");
    const [filterText, setFilterText] = useState("");

    useEffect(() => {
        fetchProductsAndServicesData(); //fetch page 0 of sales
    }, [page, perPage, sortDirection, sortBy, filterText, serviceFilter]);

    async function fetchProductsAndServicesData() {
        const response = await ProductsAndServicesService.getProductsAndServicesByMechanicIdPaginated(
            loggedMechanic.id,
            page,
            perPage,
            sortBy,
            sortDirection,
            filterText,
            serviceFilter === "product" ? false : true //false product, true service
        );

        response.content.map((pas) => {
            //setting default values for them, this is GAMBIARRA

            pas.price = pas.price + "";
            pas.discount = "0.0";
            pas.quantity = 1;
        });

        // console.log(response.content);
        setTotalRows(response.totalElements);
        setPSTableData(response.content);
    }

    const handlePageChange = (newPage) => {
        setPage(newPage - 1);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSortChange = async (column, sortDirection) => {
        setSortBy(column.id);
        setSortDirection(sortDirection.toUpperCase());
    };

    const columns = [
        {
            name: "Venda",
            selector: (row) => row.name,
            width: "30%",
            sortable: true,
        },
        {
            name: "Valor",
            cell: (row) => (
                <div key={`variantlist1-${row.idx}`}>
                    <StyledCurrencyInput
                        intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                        id={`sku-${row.idx}`}
                        name="price"
                        required
                        placeholder="0,00"
                        prefix="R$ "
                        decimalsLimit={2}
                        value={row.price}
                        onValueChange={(value, name) => editTableDataItem(row, name, value)}
                        style={{ color: "purple" }}
                    />
                </div>
            ),
            width: "15%",
            sortable: true,
        },
        {
            name: "Desc. Un.",
            cell: (row) => (
                <div key={`variantlist2-${row.idx}`}>
                    <StyledCurrencyInput
                        intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                        id={`sku-${row.idx}`}
                        name="discount"
                        required
                        placeholder="0,00"
                        prefix="R$ -"
                        decimalsLimit={2}
                        value={row.discount}
                        onValueChange={(value, name) => editTableDataItem(row, name, value)}
                        style={{ color: "darkred" }}
                    />
                </div>
            ),
            width: "15%",
            sortable: true,
        },
        {
            name: "Qtd.",
            cell: (row) => (
                <div key={`variantlist3-${row.idx}`}>
                    <TextField
                        name={`sku-${row.idx}`}
                        id={`sku-${row.idx}`}
                        key={`sku-${row.idx}`}
                        data-idx={row.idx}
                        type="number"
                        required
                        onChange={(event) => editTableDataItem(row, "quantity", event.target.value)}
                        value={row.quantity}
                    />
                </div>
            ),
            width: "15%",
            sortable: true,
        },
        {
            name: "Total",
            selector: (row) =>
                new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                }).format(row.price.replace(",", ".") * row.quantity - row.discount.replace(",", ".") * row.quantity),
            width: "15%",
            sortable: true,
            style: (row) => ({
                color: "blue",
            }),
        },
        {
            name: "Ação",
            cell: (row) => (
                <IconButton id={row.ID} variant="contained" onClick={() => handleDeleteItemFromTable(row)} style={{ color: "#006ba8" }}>
                    <DeleteIcon />
                </IconButton>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            width: "10%",
            button: true,
        },
    ];

    const productsAndServicesColumns = [
        {
            id: "name",
            name: "Venda",
            selector: (row) => row.name,
            width: "55%",
            sortable: true,
        },
        {
            id: "price",
            name: "Valor",
            selector: (row) =>
                userConfigs.hideProductServicePrice
                    ? "*****"
                    : new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                      }).format(row.price),
            width: "30%",
            sortable: true,
        },
        {
            name: "",
            width: "15%",
            cell: (row) => (
                <IconButton id={row.ID} variant="contained" onClick={() => addTableDataItem(row)} style={{ color: "#006ba8" }}>
                    <AddIcon />
                </IconButton>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const paginationComponentOptions = {
        rowsPerPageText: "Linhas",
        rangeSeparatorText: "de",
    };

    useEffect(() => {
        if (inputs.licensePlate.length >= 3) fetchVehicleData();
    }, [inputs.licensePlate]);

    useEffect(() => {
        calcTableTotalPrice();
    }, [tableData]);

    async function fetchVehicleData() {
        let vehiclesData = await MechanicsService.getClientsVehiclesByMechanicId(loggedMechanic.id, inputs.licensePlate);
        var vListComplete = [];
        vehiclesData.content.map((v) => {
            vListComplete.push({
                licensePlate: v.licensePlate,
                appUserId: v.appUser.id,
                appUserName: v.appUser.name,
                appUserCpf: v.appUser.cpf,
            });
        });
        setVehicles(vListComplete);
    }

    const handleInputsChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleAutocompleteChange = (event, dataValue) => {
        if (inputs.licensePlate !== "") {
            const name = "licensePlate";
            const value = dataValue.licensePlate;
            setInputs((values) => ({ ...values, [name]: value }));
            setInputs((values) => ({
                ...values,
                client: dataValue.appUserName,
                clientId: dataValue.appUserId,
                clientCpf: dataValue.appUserCpf,
            }));
        }
    };

    const handleTextAutocompleteChange = (event) => {
        const name = "licensePlate";
        setInputs((values) => ({ ...values, [name]: event.target.value.toUpperCase() }));
    };

    function onClear() {
        setInputs((values) => ({ ...values, licensePlate: "" }));
    }

    const handleToggleChange = (event, newFilter) => {
        setServiceFilter(newFilter);
    };

    const addTableDataItem = (row) => {
        // console.log(row);
        userConfigs.hideProductServicePrice ? setTableData([...tableData, { ...row, price: "0,0" }]) : setTableData([...tableData, row]);
    };

    const editTableDataItem = (row, name, value) => {
        const changeData = [];
        tableData.map((t) => changeData.push(t));
        if (changeData.length > 0) {
            const index = changeData.indexOf(row);
            if (index > -1) {
                if (name === "price") {
                    if (value === null || value === "" || value === undefined) changeData[index].price = "0,0";
                    else changeData[index].price = value;
                }
                if (name === "discount") {
                    if (value === null || value === "" || value === undefined) changeData[index].discount = "0,0";
                    else changeData[index].discount = value;
                }
                if (name === "quantity") if (!isNaN(value) && value > 0) if (!value.includes(",")) changeData[index].quantity = value;
            }
            setTableData(changeData);
        }
    };

    const handleDeleteItemFromTable = (row) => {
        const changeData = [];
        tableData.map((t) => changeData.push(t));
        if (changeData.length > 0) {
            const index = changeData.indexOf(row);
            if (index > -1) {
                changeData.splice(index, 1); // 2nd parameter means remove one item only
            }
            setTableData(changeData);
        }
    };

    function calcTableTotalPrice() {
        var totalPrice = 0;
        tableData.map((t) => {
            totalPrice += t.price.replace(",", ".") * t.quantity - t.discount.replace(",", ".") * t.quantity;
        });
        setTableTotalPrice(totalPrice);
    }

    const handleAddSale = async (event) => {
        event.preventDefault();

        if (tableData.length === 0) {
            alert("Você precisa adicionar um produto ou serviço!");
            return;
        }

        const body = {
            date: inputs.date,
            notes: inputs.notes,
            vehicleLicensePlate: inputs.licensePlate,
            cpf: inputs.clientCpf,
            kilometers: inputs.kilometers,
            mechanicId: loggedMechanic.id,
            soldProductOrServices: tableData.map((mps) => {
                return {
                    name: mps.name,
                    price: Number(mps.price.replace(",", ".")),
                    description: mps.description,
                    discount: Number(mps.discount.replace(",", ".")),
                    activityName: mps.activityName,
                    quantity: mps.quantity,
                    type: mps.type,
                };
            }),
        };
        // console.log(body);
        await SalesService.createSale(body)
            .then((x) => {
                history.push("/dashboard/registros");
                alert("Venda realizada com sucesso!");
            })
            .catch(() => alert("Erro ao realizar esta venda!"));
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText("");
            }
        };

        return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder="Filtrar por Nome" />;
    }, [filterText]);

    return (
        <Container>
            <Box
                mb={3}
                fontWeight="bold"
                display="flex"
                flexDirection="row"
                color="#666666"
                fontSize={{
                    xs: "h7.fontSize",
                    sm: "h7.fontSize",
                    md: "h6.fontSize",
                    xl: "h5.fontSize",
                }}
            >
                <IconButton style={{ marginRight: "10px" }} onClick={() => history.push("/dashboard/registros")}>
                    <ArrowBackIosNewIcon />
                </IconButton>
                <div style={{ paddingTop: "8px" }}>Novo Registro</div>
            </Box>
            <form onSubmit={handleAddSale}>
                <FormContainer>
                    <ClientDetailsContainer>
                        <Box m={3} display="flex" flexDirection="row" width="96%" gap="15px" justifyItems="space-between">
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={vehicles}
                                getOptionLabel={(option) => option.licensePlate}
                                onChange={(event, value, reason) => {
                                    if (reason === "clear") onClear();
                                    else handleAutocompleteChange(event, value);
                                }}
                                renderInput={(params) => <TextField {...params} required label="Veículo" onChange={(event) => handleTextAutocompleteChange(event)} />}
                                style={{ width: "100%", marginBottom: "15px" }}
                            />
                            <TextField
                                value={inputs.client}
                                name="client"
                                label="Cliente"
                                variant="filled"
                                InputProps={{ readOnly: true }}
                                style={{ marginBottom: "15px", width: "100%" }}
                                onChange={handleInputsChange}
                            />
                            <TextField
                                name="date"
                                defaultValue={moment(inputs.date).local().format("YYYY-MM-DD")}
                                required
                                type="date"
                                label="Data"
                                variant="outlined"
                                style={{ marginBottom: "15px", width: "100%" }}
                                onChange={handleInputsChange}
                            />
                            <TextField
                                name="kilometers"
                                type="number"
                                label="Quilometragem (Km)"
                                variant="outlined"
                                style={{ marginBottom: "15px", width: "100%" }}
                                onChange={(e) => {
                                    e.preventDefault();
                                    e.target.value.replace(",", ".");
                                    handleInputsChange(e);
                                }}
                                onWheel={(e) => e.target.blur()}
                            />
                        </Box>
                    </ClientDetailsContainer>
                    <ProductListContainer>
                        <Box
                            m={2}
                            fontWeight="bold"
                            color="#666666"
                            fontSize={{
                                xs: "h7.fontSize",
                                sm: "h7.fontSize",
                                md: "h6.fontSize",
                                xl: "h6.fontSize",
                            }}
                        >
                            Produtos e Serviços
                        </Box>
                        <Box width="100%" display="flex" justifyContent="flex-end">
                            <ToggleButtonGroup
                                color="primary"
                                value={serviceFilter}
                                exclusive
                                onChange={handleToggleChange}
                                style={{
                                    height: "25px",
                                    marginRight: "15px",
                                    marginBottom: "2px",
                                }}
                            >
                                <ToggleButton value="product">Produto</ToggleButton>
                                <ToggleButton value="service">Serviço</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box style={{ maxHeight: "47vh", overflowY: "scroll" }}>
                            <DataTable
                                columns={productsAndServicesColumns}
                                data={psTableData}
                                pagination
                                paginationRowsPerPageOptions={[10]}
                                paginationComponentOptions={paginationComponentOptions}
                                sortServer
                                onSort={handleSortChange}
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                                persistTableHead
                            />
                        </Box>
                        <Box display="flex" justifyContent="right" mr={3} mt={1}>
                            <Button
                                variant="text"
                                size="small"
                                onClick={() =>
                                    setModal(<NewProductServiceModal isProduct={serviceFilter === "product" ? true : false} updateTable={fetchProductsAndServicesData} />)
                                }
                            >
                                <AddIcon fontSize="small" />
                                Add {serviceFilter === "product" ? "Produto" : "Serviço"}
                            </Button>
                        </Box>
                    </ProductListContainer>
                    <DetailsTableContainer style={{ backgroundColor: "#d1dae0" }}>
                        <TableContainer>
                            <DataTable columns={columns} data={tableData} />
                        </TableContainer>
                        <AddSaleContainer>
                            <Box maxWidth="200px" fontWeight="bold">
                                <Typography>
                                    <b>Total: </b>
                                    {new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    }).format(tableTotalPrice)}
                                </Typography>
                            </Box>
                            <Box paddingTop="15px">
                                <Button variant="contained" type="submit">
                                    Adicionar Venda
                                </Button>
                            </Box>
                        </AddSaleContainer>
                    </DetailsTableContainer>
                    <NotesContainer>
                        <TextField name="notes" label="Notas" variant="filled" multiline rows={3} style={{ marginBottom: "55px", width: "100%" }} onChange={handleInputsChange} />
                    </NotesContainer>
                </FormContainer>
            </form>
        </Container>
    );
};

export default NewSale;
