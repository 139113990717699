import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useModalContext } from "../../../contexts/ModalContext";
import TextField from "@mui/material/TextField";
import { Container, FormContainer } from "./styles";
import "react-data-table-component-extensions/dist/index.css";
import Button from "@mui/material/Button";
import * as ClientsService from "../../../services/clients";
import { useAuth } from "../../../hooks/useAuth";

const NewClientModal = ({ updateTable }) => {
    const { setModal } = useModalContext();
    const handleClose = () => setModal(null);
    const { loggedMechanic } = useAuth();
    const [email, setEmail] = useState("");

    const handleAddClient = async (event) => {
        event.preventDefault();

        if (email === "") {
            alert("Você precisa especificar um Email!");
            return;
        }

        await ClientsService.addClient(loggedMechanic.id, email)
            .then((x) => {
                setModal(null);
                updateTable();

                const alertMessage = x ? "Cliente adicionado" : "Este cliente já é seu!";
                alert(alertMessage);
            })
            .catch(() => alert("Erro ao adicionar este cliente!"));
    };

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container>
                <Box
                    m={3}
                    fontWeight="bold"
                    color="#666666"
                    fontSize={{ xs: "h7.fontSize", sm: "h7.fontSize", md: "h6.fontSize", xl: "h5.fontSize" }}
                >
                    Novo Cliente
                </Box>
                <form onSubmit={handleAddClient}>
                    <FormContainer>
                        <Typography style={{ marginRight: "25px", marginLeft: "25px", marginTop: "15px" }}>
                            <b>Email: </b>
                        </Typography>
                        <TextField
                            value={email}
                            required
                            name="email"
                            placeholder="carlos@gmail.com"
                            variant="outlined"
                            onChange={(event) => setEmail(event.target.value)}
                            style={{ width: "250px" }}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            style={{ width: "150px", maxHeight: "40px", marginTop: "8px", marginLeft: "25px" }}
                        >
                            Adicionar
                        </Button>
                    </FormContainer>
                </form>
            </Container>
        </Modal>
    );
};

export default NewClientModal;
