import React from 'react';

import { Container } from './styles';

const SideNavSectionItem = ({ pageName, isSelected }) => {
  return (
    <Container className={isSelected ? 'active' : ''}>{pageName}</Container>
  );
};

export default SideNavSectionItem;
