import React from "react";
import { useModalContext } from "../../../contexts/ModalContext";

const ModalContainer = () => {
    const { modal } = useModalContext();

    return <React.Fragment>{modal}</React.Fragment>;
};

export default ModalContainer;
