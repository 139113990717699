import React from 'react';
import { Switch } from 'react-router-dom';
import App from '../App';
import Login from '../pages/Login';
import LoginPassRecover from '../pages/LoginPassRecover';
import NotFound from '../pages/NotFound';
// import NotFound from '../pages/NotFound';
import Route from './Route';

const MainRoutes = () => {
  return (
    <Switch>
      <Route path="/dashboard" exact isPrivate component={App} />
      <Route path="/dashboard/*" exact isPrivate component={App} />
      <Route path="/login" exact component={Login} />
      <Route path="/login/recuperar-senha" exact component={LoginPassRecover} />
      <Route path="/*" component={Login} />
    </Switch>
  );
};

export default MainRoutes;
