import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useModalContext } from "../../../contexts/ModalContext";
import TextField from "@mui/material/TextField";
import { Container, FormContainer } from "./styles";
import "react-data-table-component-extensions/dist/index.css";
import Button from "@mui/material/Button";
import { useAuth } from "../../../hooks/useAuth";
import * as ClientsService from "../../../services/clients";
import ConfirmationModal from "../../../components/ui/ConfirmationModal";

var moment = require("moment");

const ClientDetailsModal = ({ data, updateTable }) => {
    const { setModal } = useModalContext();
    const [confirmationModal, setConfirmationModal] = useState({ open: false, choice: "remove" });
    const inputs = {
        uniqueIdentifier: data.uniqueIdentifier,
        name: data.name,
        email: data.email,
        phone: data.phone,
        birthDate: data.birthDate,
    };
    const { loggedMechanic } = useAuth();

    const handleClose = () => setModal(null);

    const handleDelete = async () => {
        await ClientsService.removeClient(loggedMechanic.id, data.uniqueIdentifier).then((x) => {
            updateTable();
            setModal(null);
            if (x) alert("Cliente removido!");
        });
    };

    return (
        <>
            <Modal open={true} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Container>
                    <Box m={3} fontWeight="bold" color="#666666" fontSize={{ xs: "h7.fontSize", sm: "h7.fontSize", md: "h6.fontSize", xl: "h5.fontSize" }}>
                        Detalhes Cliente
                    </Box>
                    <FormContainer>
                        <TextField
                            value={inputs.uniqueIdentifier}
                            name="uniqueIdentifier"
                            label="Identificador"
                            InputProps={{ readOnly: true }}
                            variant="outlined"
                            style={{ width: "100%" }}
                        />
                        <TextField value={inputs.name} name="name" label="Nome Completo" InputProps={{ readOnly: true }} variant="outlined" style={{ width: "100%" }} />
                        <TextField value={inputs.email} name="email" label="Email" InputProps={{ readOnly: true }} variant="outlined" style={{ width: "100%" }} />
                        <TextField value={inputs.phone} name="phone" label="Telefone" InputProps={{ readOnly: true }} variant="outlined" style={{ width: "100%" }} />
                        <TextField
                            defaultValue={moment(inputs.birthDate).local().format("YYYY-MM-DD")}
                            type="date"
                            name="birthDate"
                            label="Data de Nascimento"
                            InputProps={{ readOnly: true }}
                            variant="outlined"
                            style={{ width: "100%" }}
                        />
                    </FormContainer>
                    <Box display="flex" justifyContent="flex-end" padding="20px" justifySelf="center">
                        <Button variant="outlined" color="error" onClick={() => setConfirmationModal({ open: true, choice: "remove" })} style={{ marginRight: "4px" }}>
                            Remover Cliente
                        </Button>
                        <Button variant="contained" onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </Box>
                </Container>
            </Modal>
            <ConfirmationModal open={confirmationModal.open} setOpen={setConfirmationModal} subText={`Deseja mesmo remover este cliente?`} handleConfirmation={handleDelete} />
        </>
    );
};

export default ClientDetailsModal;
