import styled from 'styled-components';

export const Container = styled.div`
    grid-area: APPCONTAINER;

    background-color: #f5f5f5;

    overflow: scroll;
    padding: 24px;
    padding-bottom: 0px;    

    justify-content: center;
`;
