import React from 'react';
import SubRoutes from '../../../routes/subRoutes';
import { Container } from './styles';

const AppContainer = () => {

    return (
        <Container>
            <SubRoutes/>
        </Container>
    );
};

export default AppContainer;