import baseUrl from "../utils/baseUrl";
import axios from "./api";

const getSalesByMechanicId = async (mechanicId, page, pageSize, sortBy, sortDirection, filterText) =>
    await axios
        .get(
            baseUrl +
                `/mechanic-sales/mechanic-id?mechanicId=${mechanicId}&pageNumber=${page}&pageSize=${pageSize}&sortDirection=${sortDirection}&sortBy=${sortBy}&licensePlate=${filterText}`
        )
        .then((response) => response.data);

const createSale = async (body) => {
    return await axios.post(baseUrl + `/mechanic-sales/save`, body).then((response) => response.data);
};

const updateSale = async (saleId, body) => {
    return await axios.put(baseUrl + `/mechanic-sales/${saleId}`, body).then((response) => response.data);
};

const deleteSale = async (saleId) => {
    return await axios.delete(baseUrl + `/mechanic-sales/${saleId}`).then((response) => response.data);
};

export { getSalesByMechanicId, createSale, updateSale, deleteSale };
