import styled from 'styled-components';
import Card from '@mui/material/Card';
import CurrencyInput from 'react-currency-input-field';

export const Container = styled(Card)`
    padding: 5px;
    max-width: 900px;
    max-height: 800px;
    color: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #f5f5f5;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    direction: column;
    height: ${props => props.isProduct ? "320px" : "400px"};
    width: 400px;
    margin: 20px;
    justify-items: space-between;
`;


export const StyledCurrencyInput = styled(CurrencyInput)`
    width: 100%;
    height: 50px;
    font-size: 15px;
`;



