import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import GroupIcon from "@mui/icons-material/Group";
import BuildIcon from "@mui/icons-material/Build";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import InsightsIcon from '@mui/icons-material/Insights';

export const SideNavItems = {
    app: "dashboard",
    modules: [
        {
            items: [
                {
                    path: "/dashboard/registros",
                    page: "Página de Registros",
                },
            ],
            name: "Registros",
            icon: <ShoppingCartCheckoutIcon />,
        },
        {
            items: [
                {
                    path: "/dashboard/clientes",
                    page: "Página de Clientes",
                },
            ],
            icon: <GroupIcon />,
            name: "Clientes",
        },
        {
            items: [
                {
                    path: "/dashboard/promoções",
                    page: "Página de Promoções",
                },
            ],
            icon: <InsightsIcon />,
            name: "Promoções",
        },
        {
            items: [
                {
                    path: "/dashboard/notificações",
                    page: "Página de Notificações",
                },
            ],
            icon: <NotificationsActiveIcon />,
            name: "Notificações",
        },
        // {
        //     items: [
        //         {
        //             path: "/dashboard/gerenciar/produtos",
        //             page: "Produtos",
        //         },
        //         {
        //             path: "/dashboard/gerenciar/serviços",
        //             page: "Serviços",
        //         },
        //         {
        //             path: "/dashboard/gerenciar/usuarios",
        //             page: "Usuários",
        //         },
        //     ],
        //     icon: <BuildIcon />,
        //     name: "Gerenciar",
        // },
        {
            items: [
                {
                    path: "/dashboard/configurações",
                    page: "Configurações",
                },
            ],
            icon: <ManageAccountsIcon />,
            name: "Configurações",
        },
        // {
        //     items: [
        //         {
        //             path: "/dashboard/auditoria",
        //             page: "Auditoria",
        //         },
        //     ],
        //     icon: <ManageSearchIcon />,
        //     name: "Auditoria",
        // },
        {
            items: [
                {
                    path: "/dashboard/suporte",
                    page: "Suporte",
                },
            ],
            icon: <SupportAgentIcon />,
            name: "Suporte",
        },
    ],
};
