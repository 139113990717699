import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useModalContext } from "../../../contexts/ModalContext";
import TextField from "@mui/material/TextField";
import {
    ClientDetailsContainer,
    Container,
    DetailsTableContainer,
    FormContainer,
    AddSaleContainer,
    TableContainer,
} from "./styles";
import { IconButton } from "@mui/material";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import * as SalesService from "../../../services/sales";
import * as MechanicsService from "../../../services/mechanics";
import { useAuth } from "../../../hooks/useAuth";
import Autocomplete from "@mui/material/Autocomplete";
import ConfirmationModal from "../../../components/ui/ConfirmationModal";

var moment = require("moment");

const SaleDetailsModal = ({ data, updateTable }) => {
    const { setModal } = useModalContext();
    const [confirmationModal, setConfirmationModal] = useState({ open: false, choice: "update" });
    const { loggedMechanic } = useAuth();
    const [tableData, setTableData] = useState(data.soldProductOrServices);
    const [vehicles, setVehicles] = useState();
    const [vehiclesList, setVehiclesList] = useState();
    const [inputs, setInputs] = useState({
        date: data.date,
        kilometers: data.kilometers,
        client: data.cpf,
        vehicle: data.vehicleLicensePlate,
        notes: data.notes,
    });

    const handleClose = () => setModal(null);

    const columns = [
        {
            name: "Venda",
            selector: (row) => row.name,
            sortable: true,
            width: "30%",
        },
        {
            name: "Valor Un.",
            selector: (row) =>
                new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(row.price),
            sortable: true,
            width: "15%",
        },
        {
            name: "Desc. Un.",
            selector: (row) =>
                new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(row.discount),
            sortable: true,
            width: "15%",
            style: (row) => ({
                color: "purple",
            }),
        },
        {
            name: "Qtd.",
            selector: (row) => row.quantity,
            sortable: true,
            width: "10%",
        },
        {
            name: "Total",
            selector: (row) =>
                new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                    row.price * row.quantity - row.discount * row.quantity
                ),
            sortable: true,
            width: "15%",
            style: (row) => ({
                color: "blue",
            }),
        },
        {
            name: "Ação",
            cell: (row) => (
                <IconButton
                    id={row.ID}
                    variant="contained"
                    onClick={() => handleDeleteItemFromTable(row)}
                    style={{ color: "#006ba8" }}
                >
                    <DeleteIcon />
                </IconButton>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: "15%",
        },
    ];

    useEffect(() => {
        fetchVehicleData();
    }, []);

    async function fetchVehicleData() {
        let vehiclesData = await MechanicsService.getClientsVehiclesByMechanicId(loggedMechanic.id);
        setVehicles(vehiclesData);

        let vList = [];
        vehiclesData.map((v) => vList.push(v.licensePlate));
        setVehiclesList(vList);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleDeleteItemFromTable = (row) => {
        const changeData = [];
        tableData.map((t) => changeData.push(t));
        if (changeData.length > 1) {
            const index = changeData.indexOf(row);
            if (index > -1) {
                changeData.splice(index, 1); // 2nd parameter means remove one item only
            }
            setTableData(changeData);
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();

        const body = {
            id: data?.id,
            date: inputs.date,
            kilometers: inputs.kilometers,
            notes: inputs.notes,
            vehicleLicensePlate: inputs.vehicle,
            mechanicId: loggedMechanic.id,
            soldProductOrServices: tableData, //hope this works
        };
        // console.log(body);
        await SalesService.updateSale(data.id, body)
            .then((x) => {
                updateTable();
                setModal(null);
                alert("Venda atualizada com sucesso!");
            })
            .catch(() => alert("Erro ao atualizar esta venda!"));
    };

    const handleDelete = async () => {
        await SalesService.deleteSale(data.id)
            .then((x) => {
                updateTable();
                setModal(null);
                alert("Venda deletada com sucesso!");
            })
            .catch(() => alert("Erro ao deletar esta venda!"));
    };

    function tableTotalPrice() {
        var totalPrice = 0;
        tableData.map((t) => {
            totalPrice += t.price * t.quantity - t.discount * t.quantity;
        });
        return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(totalPrice);
    }

    const onSubmit = (event) => {
        //this will make sure the url will not change
        event.preventDefault();

        //foi um parto achar esse id
        let id = event.nativeEvent.submitter.id;

        if (id === "updateBtn") {
            setConfirmationModal({ open: true, choice: "update" });
        } else if (id === "deleteBtn") {
            setConfirmationModal({ open: true, choice: "delete" });
        } else {
            //do nothing
        }
    };

    return (
        <>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Container>
                    <Box
                        m={3}
                        fontWeight="bold"
                        color="#666666"
                        fontSize={{ xs: "h7.fontSize", sm: "h7.fontSize", md: "h6.fontSize", xl: "h5.fontSize" }}
                    >
                        Detalhes Registro
                    </Box>
                    <form onSubmit={onSubmit}>
                        <FormContainer>
                            <ClientDetailsContainer>
                                <Box m={3} justifyItems="center">
                                    {/* <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={vehiclesList}
                                    onChange={(event, value) => handleAutocompleteChange(event, value)}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Veículo" />}
                                    style={{width: '100%', marginBottom: '15px'}}
                                    InputProps={{ readOnly: true, }}
                                /> */}
                                    <TextField
                                        value={inputs.vehicle}
                                        required
                                        InputProps={{ readOnly: true }}
                                        name="vehicle"
                                        label="Veículo"
                                        variant="filled"
                                        style={{ marginBottom: "15px" }}
                                        // onChange={handleChange}
                                    />
                                    <TextField
                                        value={inputs.client}
                                        required
                                        InputProps={{ readOnly: true }}
                                        name="client"
                                        label="Cliente CPF"
                                        variant="filled"
                                        style={{ marginBottom: "15px" }}
                                        // onChange={handleChange}
                                    />
                                    <TextField
                                        defaultValue={moment(inputs.date).local().format("YYYY-MM-DD")}
                                        required
                                        InputProps={{ readOnly: true }}
                                        type="date"
                                        name="date"
                                        label="Data"
                                        variant="filled"
                                        style={{ marginBottom: "15px", width: "100%" }}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        InputProps={{ readOnly: true }}
                                        value={inputs.kilometers}
                                        name="kilometers"
                                        type="number"
                                        label="Quilometragem (Km)"
                                        variant="filled"
                                        style={{ marginBottom: "15px" }}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            e.target.value.replace(",", ".");
                                            handleChange(e);
                                        }}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                    <TextField
                                        InputProps={{ readOnly: true }}
                                        value={inputs.notes}
                                        name="notes"
                                        label="Notas"
                                        variant="filled"
                                        multiline
                                        rows={7}
                                        style={{ marginBottom: "55px" }}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </ClientDetailsContainer>
                            <DetailsTableContainer>
                                <TableContainer>
                                    <DataTable columns={columns} data={tableData} />
                                </TableContainer>
                                <AddSaleContainer>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                        maxWidth="250px"
                                        fontWeight="bold"
                                    >
                                        <Typography>
                                            <b>Total: </b>
                                            {tableTotalPrice()}
                                        </Typography>
                                        <Typography>
                                            | {tableData.length} {tableData.length > 1 ? "itens" : "item"}
                                        </Typography>
                                    </Box>
                                    <Box paddingTop="15px">
                                        <Button
                                            id="deleteBtn"
                                            variant="outlined"
                                            color="error"
                                            onClick={() => setConfirmationModal({ open: true, choice: "delete" })}
                                            style={{ marginRight: "4px" }}
                                        >
                                            Deletar
                                        </Button>
                                        <Button
                                            id="updateBtn"
                                            variant="contained"
                                            onClick={() => setConfirmationModal({ open: true, choice: "update" })}
                                        >
                                            Atualizar
                                        </Button>
                                    </Box>
                                </AddSaleContainer>
                            </DetailsTableContainer>
                        </FormContainer>
                    </form>
                </Container>
            </Modal>
            <ConfirmationModal
                open={confirmationModal.open}
                setOpen={setConfirmationModal}
                subText={`Deseja mesmo ${
                    confirmationModal.choice === "update" ? "atualizar" : "deletar"
                } esta venda?`}
                handleConfirmation={confirmationModal.choice === "update" ? handleUpdate : handleDelete}
            />
        </>
    );
};

export default SaleDetailsModal;
