import styled from "styled-components";
import Card from "@mui/material/Card";

export const Container = styled.div`
    margin-left: 10px;
`;

export const FormContainer = styled(Card)`
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    direction: column;
    height: 100px;
    margin: 5px;
    justify-items: space-between;
`;
