import baseUrl from "../utils/baseUrl";
import axios from "./api";

const getProductsAndServicesByMechanicIdPaginated = async (mechanicId, page, pageSize, sortBy, sortDirection, filterText, type) =>
    await axios
        .get(
            baseUrl +
                `/mechanic-products-or-services/mechanic-id?mechanicId=${mechanicId}&pageNumber=${page}&pageSize=${pageSize}&sortDirection=${sortDirection}&sortBy=${sortBy}&name=${filterText}&type=${type}`
        )
        .then((response) => response.data);

const createProductAndService = async (body) => {
    return await axios.post(baseUrl + `/mechanic-products-or-services/save`, body).then((response) => response.data);
};

const updateProductAndService = async (id, body) => {
    return await axios.put(baseUrl + `/mechanic-products-or-services/${id}`, body).then((response) => response.data);
};

const deleteProductAndService = async (id, body) => {
    return await axios.delete(baseUrl + `/mechanic-products-or-services/${id}`).then((response) => response.data);
};

export { getProductsAndServicesByMechanicIdPaginated, createProductAndService, updateProductAndService, deleteProductAndService };
