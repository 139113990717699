import React from "react";

import { FormContainer } from "./styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { ReactComponent as Logo } from "../../../assets/img/OiCarLogoDark.svg";

const PassRecoverConfirmation = () => {
    const history = useHistory();

    return (
        <FormContainer>
            <Grid container direction="column" rowSpacing={2} justifyContent="center" alignContent="center" marginTop={3}>
                <Grid item display="grid" justifyItems="center">
                    <Logo style={{ width: "120px", height: "50px" }} />
                </Grid>
                <Grid item marginTop={2} marginLeft={15}>
                    <ThumbUpAltIcon htmlColor="#666666" style={{ height: "50px", width: "50px" }} />
                </Grid>
                <Grid item justifyItems="center" marginTop={2}>
                    <Typography fontSize={16} fontWeight="bold">
                        Senha alterada com sucesso!
                    </Typography>
                    <Typography fontSize={16}>Faça seu login agora.</Typography>
                </Grid>
                <Grid item marginTop={4}>
                    <Button variant="contained" onClick={() => history.push("/login")} style={{ width: 300, height: 50 }}>
                        Fazer Login
                    </Button>
                </Grid>
            </Grid>
        </FormContainer>
    );
};

export default PassRecoverConfirmation;
