import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { FormContainer, ButtonContainer } from "./styles";
import "react-data-table-component-extensions/dist/index.css";
import { TextField, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import * as UsersService from "../../../services/users";
import { useAuth } from "../../../hooks/useAuth";
import Avatar from "@mui/material/Avatar";

var moment = require("moment");

const Profile = () => {
    const { loggedUser, setLoggedUser } = useAuth();
    const [inputs, setInputs] = useState(loggedUser);
    const history = useHistory();
    const [isUpdating, setIsUpdating] = useState(false);

    const handleInputsChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleSaveButtonChange = async (event) => {
        //this will make sure the url will not change
        event.preventDefault();
        if (isUpdating) {
            await handleUpdateUser(event);
            setIsUpdating(!isUpdating);
        } else {
            setIsUpdating(!isUpdating);
        }
    };

    const handleUpdateUser = async (event) => {
        //this will make sure the url will not change
        event.preventDefault();
        const body = {
            name: inputs.name,
            email: inputs.email,
            birthDate: moment(inputs.birthDate).toDate(),
            phone: inputs.phone,
        };

        await UsersService.updateUser(inputs.id, body)
            .then((user) => {
                // setModal(null);
                // updateTable();
                setLoggedUser(user);
                alert(`Dados atualizados com sucesso!`);
            })
            .catch(() => alert(`Erro ao atualizar seus dados!`));
    };

    return (
        <form onSubmit={handleSaveButtonChange}>
            <FormContainer>
                <Box display="flex" flexDirection="row">
                    <Avatar alt={inputs.name} sx={{ width: 35, height: 35 }} />
                    <Typography variant="h5" color="#666666" style={{ width: "100%", marginLeft: "20px", marginTop: "2px" }}>
                        Olá, <b>{inputs.name}</b>!
                    </Typography>
                </Box>
                <Typography variant="h6" color="#666666" style={{ width: "100%", marginBottom: "5px" }}>
                    Seus dados:
                </Typography>
                <TextField
                    value={inputs.uniqueIdentifier}
                    required
                    label="Identificador"
                    sx={{ input: { color: "gray" } }}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    style={{ width: "100%" }}
                />
                <TextField
                    value={inputs.name}
                    name="name"
                    required
                    label="Nome Completo"
                    sx={{ input: { color: isUpdating ? "black" : "gray" } }}
                    InputProps={{ readOnly: isUpdating ? false : true }}
                    variant="outlined"
                    style={{ width: "100%" }}
                    onChange={handleInputsChange}
                />
                <TextField
                    value={inputs.email}
                    name="email"
                    type="email"
                    required
                    label="Email"
                    sx={{ input: { color: isUpdating ? "black" : "gray" } }}
                    InputProps={{ readOnly: isUpdating ? false : true }}
                    variant="outlined"
                    style={{ width: "100%" }}
                    onChange={handleInputsChange}
                />
                <TextField
                    value={inputs.phone}
                    name="phone"
                    required
                    label="Telefone"
                    sx={{ input: { color: isUpdating ? "black" : "gray" } }}
                    InputProps={{ readOnly: isUpdating ? false : true }}
                    variant="outlined"
                    style={{ width: "100%" }}
                    onChange={handleInputsChange}
                />
                <TextField
                    defaultValue={moment(inputs.birthDate).local().format("YYYY-MM-DD")}
                    required
                    type="date"
                    name="birthDate"
                    label="Data de Nascimento"
                    sx={{ input: { color: isUpdating ? "black" : "gray" } }}
                    InputProps={{ readOnly: isUpdating ? false : true }}
                    variant="outlined"
                    style={{ width: "100%" }}
                    onChange={handleInputsChange}
                />
            </FormContainer>
            <ButtonContainer>
                <Box display="flex" justifyContent="flex-end" padding="20px" justifySelf="center">
                    {isUpdating && (
                        <Button variant="outlined" color="error" style={{ marginRight: "4px" }} type="submit">
                            Salvar Alterações
                        </Button>
                    )}
                    <Button variant="contained" color="primary" style={{ marginRight: "4px" }} onClick={() => setIsUpdating(!isUpdating)}>
                        {isUpdating ? "Cancelar" : "Editar Dados"}
                    </Button>
                    <Button variant="outlined" style={{ marginRight: "4px" }} onClick={() => history.push("/dashboard/configurações/alterar-senha")}>
                        Alterar Senha
                    </Button>
                </Box>
            </ButtonContainer>
        </form>
    );
};

export default Profile;
