import { Card } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: top;
    overflow: auto;
`;

export const CardContainer = styled(Card)`
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 5px;
    row-gap: 15px;
`;

export const ImageContainer = styled(Card)`
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 5px;
    row-gap: 5px;
`;
