import styled from 'styled-components';
import Card from '@mui/material/Card';

export const Container = styled(Card)`
    padding: 5px;
    width: 500px;
    max-height: 500px;
    color: #000000;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #f5f5f5;
`;

export const FormContainer = styled.div`
    display: flex;
    margin: 20px;

    justify-content: flex-end;
`;


