import baseUrl from '../utils/baseUrl';
import axios from './api';

const getMechanicUserByEmail = async (email) =>
    await axios.get(baseUrl + `/users/mechanic/${email}`).then((response) => response.data);

const getMechanicUsersByMechanicId = async (mechanicId) => 
    await axios.get(baseUrl + `/users/mechanic/${mechanicId}/all`).then((response) => response.data);

const createUser = async (body) =>
    await axios.post(baseUrl + `/users/mechanic/save`, body).then((response) => response.data);

const updateUser = async (id, body) =>
    await axios.put(baseUrl + `/users/mechanic/update/${id}`, body).then((response) => response.data);

const deleteUser = async (id, body) =>
    await axios.delete(baseUrl + `/users/mechanic/${id}`, body).then((response) => response.data);

const changeUserRole = async (body) =>
    await axios.put(baseUrl + `/users/mechanic/role/change`, body).then((response) => response.data);

const changePassword = async (body) =>
    await axios.put(baseUrl + `/users/password/update`, body).then((response) => response.data);


export {
    getMechanicUserByEmail,
    getMechanicUsersByMechanicId,
    createUser,
    updateUser,
    deleteUser,
    changeUserRole,
    changePassword,
};