import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Container } from "./styles";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useModalContext } from "../../contexts/ModalContext";
import NewUserModal from "./NewUserModal";
import UserDetailsModal from "./UserDetailsModal";
import * as UsersService from "../../services/users";
import { useAuth } from "../../hooks/useAuth";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import AddIcon from "@mui/icons-material/Add";

const Users = () => {
    const { setModal } = useModalContext();
    const [tableData, setTableData] = useState();
    const { loggedMechanic } = useAuth();

    useEffect(() => {
        fetchTableData();
    }, [loggedMechanic]);

    async function fetchTableData() {
        const users = await UsersService.getMechanicUsersByMechanicId(loggedMechanic.id);
        users.map((u) => {
            u.role = u.role === "ROLE_USER_MECHANIC" ? "USER" : "ADMIN";
        });
        setTableData(users);
    }

    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
        },
        {
            name: "Nome",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Permissão",
            selector: (row) => row.role,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: "Detalhes",
            cell: (row) => (
                <Button id={row.ID} variant="outlined" onClick={() => setModal(<UserDetailsModal data={row} updateTable={fetchTableData} />)}>
                    <ManageSearchIcon />
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <Container>
            <Box
                mb={3}
                fontWeight="bold"
                color="#666666"
                fontSize={{
                    xs: "h7.fontSize",
                    sm: "h6.fontSize",
                    md: "h5.fontSize",
                }}
            >
                Gerenciar - Usuários
            </Box>
            <Button variant="contained" onClick={() => setModal(<NewUserModal updateTable={fetchTableData} />)}>
                <AddIcon fontSize="small" /> Novo Usuário
            </Button>
            <Box mt={3}>
                <Card>
                    <DataTableExtensions columns={columns} data={tableData} export={false} print={false}>
                        <DataTable pagination />
                    </DataTableExtensions>
                </Card>
            </Box>
        </Container>
    );
};

export default Users;
