import styled from "styled-components";

export const Container = styled.ul`
    grid-area: SIDENAV;

    background: #d1dae0; //#006ba8;

    box-shadow: 2px 0 5px rgba(102, 102, 102, 0.15), 1px 0 4px rgba(102, 102, 102, 0.25);
    z-index: 2;
    display: block;

    overflow: hidden;
    padding-left: 0px;
    margin: 0px;
`;
