import React from "react";
import { Container } from "./styles";
import { ReactComponent as Logo } from "../../../assets/img/OiCarLogo1.svg";

const LogoSection = () => {
    return (
        <Container style={{ zIndex: 0 }}>
            <Logo style={{ zIndex: -1 }} />
        </Container>
    );
};

export default LogoSection;
