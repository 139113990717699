import React from "react";

import { Container, FormContainer } from "./styles";
import { Button, Typography } from "@mui/material";

const Support = () => {
    return (
        <Container>
            <FormContainer>
                    <Typography variant="h6" color="#111">
                        Suporte OiCar
                    </Typography>
                    <Typography variant="h7" color="#666666">
                        Para falar com a OiCar, utilize o canal de whatsapp no botão abaixo:
                    </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        backgroundColor: "#ffe366",
                        color: "#000",
                        height: 54,
                        width: 200,
                        fontWeight: "bold",
                        boxShadow: "none",
                    }}
                    href="https://api.whatsapp.com/send?1=pt_BR&phone=555120420124"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    SUPORTE OiCar
                </Button>
            </FormContainer>
        </Container>
    );
};

export default Support;
