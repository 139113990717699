import baseUrl from "../utils/baseUrl";
import axios from "./api";

const getPromotionByMechanicId = async (mechanicId) =>
    await axios.get(
            baseUrl + `/mechanic-promotion/${mechanicId}`
        ).then((response) => response.data);

const sendPromotion = async (formData) => {
    return await axios.post(baseUrl + `/mechanic-promotion/send-images`, formData).then((response) => response.data);
};

const removePromotion = async () => {
    return await axios.delete(baseUrl + `/mechanic-promotion/remove`).then((response) => response.data);
};

export { getPromotionByMechanicId, sendPromotion, removePromotion };
