import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useModalContext } from "../../../contexts/ModalContext";
import TextField from "@mui/material/TextField";
import { Container, FormContainer, StyledCurrencyInput } from "./styles";
import "react-data-table-component-extensions/dist/index.css";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import { useAuth } from "../../../hooks/useAuth";
import * as ActivitiesService from "../../../services/activities";
import * as productsAndServicesService from "../../../services/productsAndServices";
import { Typography } from "@mui/material";
import ConfirmationModal from "../../../components/ui/ConfirmationModal";

const ProductServiceDetailsModal = ({ isProduct, data, updateTable }) => {
    const { setModal } = useModalContext();
    const handleClose = () => setModal(null);
    const { loggedMechanic } = useAuth();
    const [confirmationModal, setConfirmationModal] = useState({ open: false, choice: "update" });
    const [activities, setActivities] = useState([]);
    const [inputs, setInputs] = useState({
        id: data.id,
        name: data.name,
        price: data.price,
        description: data.description,
        //true é service, false product
        type: isProduct ? false : true,
        activityName: data.activityName,
    });

    useEffect(() => {
        fetchActivityData();
    }, [loggedMechanic]);

    async function fetchActivityData() {
        var act = await ActivitiesService.getActivities();
        setActivities(act);
    }

    const handleInputsChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleCurrencyChange = (value, name) => setInputs((values) => ({ ...values, [name]: value }));

    const handleAutocompleteChange = (event, dataValue) => {
        const name = "activityName";
        const value = dataValue.name;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    function getAutocompleteDefaultValue() {
        if (inputs.activityName == "Troca de Óleo") return { id: 2, name: "Troca de Óleo" };
        if (inputs.activityName == "Serviço em Mecânica") return { id: 8, name: "Serviço em Mecânica" };
        else return { id: 8, name: "Serviço em Mecânica" };
    }

    const handleUpdateProductService = async () => {
        //This is to avoid type error, double and string conflict on replace()
        var formattedPrice = "";
        try {
            formattedPrice = inputs.price.replace(",", ".");
        } catch {
            formattedPrice = inputs.price;
        }

        const body = {
            name: inputs.name,
            price: formattedPrice,
            description: inputs.description,
            //true é service, false product
            type: isProduct ? false : true,
            activityName: inputs.activityName,
            mechanicId: loggedMechanic.id,
        };

        await productsAndServicesService
            .updateProductAndService(inputs.id, body)
            .then((x) => {
                setModal(null);
                updateTable();
                alert(`${isProduct ? "Produto" : "Serviço"} atualizado com sucesso!`);
            })
            .catch(() => alert(`Erro ao atualizar este ${isProduct ? "Produto" : "Serviço"}!`));
    };

    const handleDelete = async () => {
        await productsAndServicesService
            .deleteProductAndService(inputs.id)
            .then((x) => {
                updateTable();
                setModal(null);
                alert(`${isProduct ? "Produto" : "Serviço"} deletado com sucesso!`);
            })
            .catch(() => alert(`Erro ao deletar este ${isProduct ? "Produto" : "Serviço"}!`));
    };

    const onSubmit = (event) => {
        //this will make sure the url will not change
        event.preventDefault();

        //foi um parto achar esse id
        let id = event.nativeEvent.submitter.id;

        if (id === "updateBtn") {
            setConfirmationModal({ open: true, choice: "update" });
        } else if (id === "deleteBtn") {
            setConfirmationModal({ open: true, choice: "delete" });
        } else {
            //do nothing
        }
    };

    return (
        <>
            <Modal open={true} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Container>
                    <Box m={3} fontWeight="bold" color="#666666" fontSize={{ xs: "h7.fontSize", sm: "h7.fontSize", md: "h6.fontSize", xl: "h5.fontSize" }}>
                        Detalhes do {isProduct ? "Produto" : "Serviço"}
                    </Box>
                    <form onSubmit={onSubmit}>
                        <FormContainer isProduct={isProduct}>
                            {!isProduct && (
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={activities}
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={getAutocompleteDefaultValue}
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    onChange={(event, value) => handleAutocompleteChange(event, value)}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Atividade" />}
                                    required
                                    style={{ width: "100%" }}
                                />
                            )}
                            <TextField value={inputs.name} name="name" required label="Nome" variant="outlined" style={{ width: "100%" }} onChange={handleInputsChange} />
                            <Box display="flex" flexDirection="row" width="100%">
                                <Typography
                                    fontWeight="bold"
                                    fontSize={{ xs: "h7.fontSize", sm: "h7.fontSize", md: "h7.fontSize", xl: "h6.fontSize" }}
                                    color={{ color: "#666666" }}
                                    style={{ marginRight: "12px", marginTop: "12px" }}
                                >
                                    Preço:{" "}
                                </Typography>
                                <StyledCurrencyInput
                                    value={inputs.price}
                                    id="input-price"
                                    name="price"
                                    required
                                    placeholder="0,00"
                                    prefix="R$"
                                    decimalsLimit={2}
                                    onValueChange={(value, name) => handleCurrencyChange(value, name)}
                                />
                            </Box>
                            <TextField
                                value={inputs.description}
                                name="description"
                                required
                                label="Descrição"
                                multiline
                                rows={5}
                                variant="outlined"
                                style={{ width: "100%" }}
                                onChange={handleInputsChange}
                            />
                        </FormContainer>
                        <Box display="flex" justifyContent="flex-end" padding="20px" justifySelf="center">
                            <Button id="deleteBtn" variant="outlined" color="error" type="submit" style={{ marginRight: "4px" }}>
                                Deletar
                            </Button>
                            <Button id="updateBtn" variant="contained" color="secondary" type="submit" style={{ marginRight: "4px" }}>
                                Atualizar
                            </Button>
                            <Button variant="contained" onClick={() => handleClose()}>
                                Fechar
                            </Button>
                        </Box>
                    </form>
                </Container>
            </Modal>
            <ConfirmationModal
                open={confirmationModal.open}
                setOpen={setConfirmationModal}
                subText={`Deseja mesmo ${confirmationModal.choice === "update" ? "atualizar" : "deletar"} este ${isProduct ? "produto" : "serviço"}?`}
                handleConfirmation={confirmationModal.choice === "update" ? handleUpdateProductService : handleDelete}
            />
        </>
    );
};

export default ProductServiceDetailsModal;
