import axios from "axios";
import { Redirect } from "react-router-dom";
import baseUrl from "../utils/baseUrl";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_DEV,
});

// Request interceptor for API calls
instance.interceptors.request.use(
    async (config) => {
        var access_token = localStorage.getItem("access_token");
        config.headers.Authorization = `Bearer ${JSON.parse(access_token)}`;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// Response interceptor for API calls
instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const access_token = await refreshToken();
            instance.defaults.headers.Authorization = `Bearer ${access_token}`;
            return instance(originalRequest);
        }
        return Promise.reject(error);
    }
);

async function refreshToken() {
    try {
        var refresh_token = localStorage.getItem("refresh_token");
        const header = {
            Authorization: `Bearer ${JSON.parse(refresh_token)}`,
        };
        const parameters = {
            method: "GET",
            headers: header,
        };
        await axios
            .get(baseUrl + "/users/token/refresh", parameters)
            .then(async (res) => {
                localStorage.setItem("access_token", JSON.stringify(res.data.access_token));
                localStorage.setItem("refresh_token", JSON.stringify(res.data.refresh_token));
                // Fazer algo caso seja feito o refresh token
                return res.data.access_token;
            })
            .catch((err) => {
                alert("Autenticação expirou, por favor faça login novamente!");
                localStorage.clear();
                window.location.pathname = "/login";
            });
    } catch (err) {
        alert("Erro na autenticação, por favor faça login novamente!");
        localStorage.clear();
        window.location.pathname = "/login";
    }
}

export default instance;
