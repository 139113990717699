import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Container, FormContainer, ButtonContainer } from "./styles";
import "react-data-table-component-extensions/dist/index.css";
import Button from "@mui/material/Button";
import * as UsersService from "../../../../services/users";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useHistory } from "react-router-dom";

const ChangePassword = () => {
    const [inputs, setInputs] = useState({
        oldPassword: "",
        newPasswordAgain: "",
        newPassword: "",
    });
    const history = useHistory();

    const handleInputsChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleChangePassword = async () => {
        if (inputs.oldPassword == "" || inputs.oldPasswordAgain == "" || inputs.newPassword == "") {
            alert(`Não deixe campos em branco!`);
            return;
        }

        if (inputs.newPassword != inputs.newPasswordAgain) {
            alert(`Sua nova senha e a confirmação da mesma não são iguais!`);
            return;
        }

        const body = {
            oldPassword: inputs.oldPassword,
            newPassword: inputs.newPassword,
        };

        await UsersService.changePassword(body)
            .then((x) => {
                alert(`Senha alterada com sucesso!`);
                history.push("/dashboard/perfil");
            })
            .catch(() => alert(`Erro ao alterar sua senha!`));
    };

    return (
        <Container>
            <Box
                mb={3}
                fontWeight="bold"
                display="flex"
                flexDirection="row"
                color="#666666"
                fontSize={{ xs: "h7.fontSize", sm: "h7.fontSize", md: "h6.fontSize", xl: "h5.fontSize" }}
            >
                <IconButton style={{ marginRight: "10px" }} onClick={() => history.push("/dashboard/configurações")}>
                    <ArrowBackIosNewIcon />
                </IconButton>
                <div style={{ paddingTop: "4px" }}>Alterar Senha</div>
            </Box>
            <FormContainer>
                <TextField name="oldPassword" required label="Senha Atual" type="password" variant="outlined" style={{ width: "100%" }} onChange={handleInputsChange} />
                <TextField name="newPassword" required label="Nova Senha" variant="outlined" type="password" style={{ width: "100%" }} onChange={handleInputsChange} />
                <TextField
                    name="newPasswordAgain"
                    required
                    label="Confirme a nova senha"
                    type="password"
                    variant="outlined"
                    style={{ width: "100%" }}
                    onChange={handleInputsChange}
                />
            </FormContainer>
            <ButtonContainer>
                <Box display="flex" justifyContent="flex-end" padding="20px" justifySelf="center">
                    <Button variant="outlined" color="error" onClick={() => handleChangePassword()} style={{ marginRight: "4px" }}>
                        Alterar Senha
                    </Button>
                    <Button variant="contained" onClick={() => history.push("/dashboard/perfil")}>
                        Cancelar
                    </Button>
                </Box>
            </ButtonContainer>
        </Container>
    );
};

export default ChangePassword;
