import React, { useState } from "react";

import Box from "@mui/material/Box";
import { Container, FormContainer } from "./styles";
import "react-data-table-component-extensions/dist/index.css";
import { useAuth } from "../../hooks/useAuth";
import Profile from "./Profile";
import TitleBarWithSwitch from "../../components/ui/TitleBarWithSwitch";

const Configurations = () => {
    const { userConfigs, handleHideProductsPrice } = useAuth();

    return (
        <Container>
            <Box
                mb={3}
                fontWeight="bold"
                color="#666666"
                fontSize={{
                    xs: "h7.fontSize",
                    sm: "h6.fontSize",
                    md: "h5.fontSize",
                }}
            >
                Configurações
            </Box>
            <Profile />
            <FormContainer>
                <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    mb={3}
                    fontWeight="bold"
                    color="#666666"
                    fontSize={{
                        xs: "h8.fontSize",
                    }}
                    rowGap={2}
                >
                    Registros / Vendas
                    <TitleBarWithSwitch
                        title="Esconder preço de produtos/serviços"
                        onSwitchChange={() => {
                            handleHideProductsPrice();
                        }}
                        isSwitchChecked={userConfigs.hideProductServicePrice}
                    />
                </Box>
            </FormContainer>
        </Container>
    );
};

export default Configurations;
