import React, { useEffect, useState } from "react";

import { Container, CardContainer, StatusContainer } from "./styles";
import Box from "@mui/material/Box";
import { Button, Card, TextField, Typography } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import * as NotificationService from "../../services/notifications"
import ConfirmationModal from "../../components/ui/ConfirmationModal";

const Status = {
    APPROVED: {
        text: 'Aprovado / Em execução',
        color: 'green' 
    },
    PENDING: {
        text: 'Esperando aprovação',
        color: 'blue' 
    },
    DENIED: {
        text: 'Negado',
        color: 'red' 
    },
    NOTSENT: {
        text: 'Esperando cadastro',
        color: 'gray' 
    }
};

const Notifications = () => {
    const { loggedMechanic } = useAuth();
    const [confirmationModal, setConfirmationModal] = useState({ open: false, choice: "update" });
    const [inputs, setInputs] = useState({
        status: Status.NOTSENT,
        title: "",
        text: ""
    });

    const handleSendForApproval = async (event) => {
        event.preventDefault();
        const body = {
            title: inputs.title,
            text: inputs.text,
            mechanicId: loggedMechanic.id
        };

        await NotificationService.sendNotification(body)
            .then(() => {
                alert(`Notificação enviada para aprovação!`);
                fetchData()
            })
            .catch(() => alert(`Erro ao enviar a notificação para aprovação!`));
    }
    
    useEffect(() => {
        fetchData()
    }, [loggedMechanic.id])

    async function fetchData() {
        await NotificationService.getNotificationByMechanicId(loggedMechanic.id)
        .then((response) => {
            setInputs(() => ({
                status: Status[response.status] ?? Status.NOTSENT,
                title: response.title ?? "",
                text: response.text ?? "",
                reason: response.reason ?? ""
            }))
        })
        console.log(inputs)
    }
    
    const handleInputsChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var limitedValue = value.slice(0, 80);

        if(name === "title") limitedValue = value.slice(0, 30);

        setInputs((values) => ({ ...values, [name]: limitedValue }));
    };


    const handleRemove = async (event) => {
        event.preventDefault();
        await NotificationService.removeNotification()
            .then(() => {
                alert(`Notificação removida com sucesso!`)
                fetchData()
                setConfirmationModal({ open: false })
            })
            .catch(() => alert(`Erro ao tentar remover esta notificação!`));
    };

    return (
        <Container>
            <Box
                mb={3}
                fontWeight="bold"
                color="#666666"
                fontSize={{
                    xs: "h7.fontSize",
                    sm: "h6.fontSize",
                    md: "h5.fontSize",
                }}
            >
                Notificações
            </Box>
            <StatusContainer>
                <Box display="flex" justifyContent="space-between">
                    <Typography fontSize={20} fontWeight="bold">
                        Sua notificação atual
                    </Typography>
                    {inputs.status !== Status.NOTSENT && <Button variant="outlined" color="error" onClick={() => setConfirmationModal({ open: true })}>
                        Remover Notificação
                    </Button>}
                </Box>
                <Box display="flex" gap={1}>
                    <Typography>Status da sua notificação: </Typography>
                    <Typography color={inputs.status.color}>{inputs.status.text}</Typography>
                </Box>
                {inputs.reason !== "" && <Box display="flex" gap={1}>
                    <Typography>Motivo: </Typography>
                    <Typography color={inputs.status.color}>{inputs.reason}</Typography>
                </Box>}
                <Typography variant="p">
                    *Será enviada para seus clientes a cada 15 dias, nas quartas-feiras às 14hrs.
                </Typography>
            </StatusContainer>
            <CardContainer>
                <Typography variant="h6" color="#111">
                    Notifique seus clientes!
                </Typography>
                <Typography variant="p" color="#666666">
                    Para enviar uma notificação, basta preencher o formulário abaixo. A notificação será enviada para o time OiCar
                    para aprovação, e assim que aprovada <b>seus clientes receberão ela a cada 15 dias nas quartas-feiras às 14hrs.</b>
                </Typography>
                <Typography variant="p" color="red">
                    *Ainda estamos desenvolvendo esta funcionalidade, e logo estaremos adicionando mais opções e detalhes para as notificações.
                </Typography>
                <form onSubmit={handleSendForApproval}>
                    <Box display="flex" flexDirection="column" gap={1.5}>
                        <TextField
                            value={inputs.title}
                            required
                            name="title"
                            label="Título"
                            helperText="*Este será o título da notificação, deve conter até 30 caractéres."
                            variant="outlined"
                            style={{ width: "100%", marginTop: "10px" }}
                            onChange={handleInputsChange}
                        />
                        <TextField
                            value={inputs.text}
                            name="text"
                            required
                            label="Conteúdo em texto"
                            helperText="*Este será o conteúdo da notificação, deve conter até 80 caractéres."
                            variant="outlined"
                            style={{ width: "100%" }}
                            onChange={handleInputsChange}
                        />

                        <Typography variant="h7" color="#666666">
                            Como sua notificação aparecerá para o cliente:
                        </Typography>

                        <Card style={{ background:"#e9e9e9", padding: "10px", maxWidth: "650px"}}>
                            <Box display="flex" flexDirection="column" gap={0.7}>
                                <Typography variant="h8" color="#666666">
                                    <b>{loggedMechanic.name + ": " + inputs.title}</b>
                                </Typography>
                                <Typography variant="h9" color="#666666">
                                    {inputs.text}
                                </Typography>
                            </Box>
                        </Card>

                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                backgroundColor: "success",
                                height: 54,
                                width: 250,
                                fontWeight: "bold",
                                boxShadow: "none",
                                marginTop: "15px"
                            }}
                            type="submit"
                        >
                            Enviar para Aprovação
                        </Button>
                    </Box>
                </form>
            </CardContainer>
            <ConfirmationModal
                open={confirmationModal.open}
                setOpen={setConfirmationModal}
                subText={"Deseja mesmo remover esta notificação? Para mandar notificações, você terá que submeter para aprovação novamente."}
                handleConfirmation={handleRemove}
            />
        </Container>
    );
};

export default Notifications;
