import React, { useState } from 'react';

import { Container } from './styles';
import PassRecoverSendEmail from './SendEmail';
import PassRecoverCodeValidation from './CodeValidation';
import PassRecoverNewPassword from './NewPassword';
import PassRecoverConfirmation from './Confirmation';


const LoginPassRecover = () => {
    const [formStep, setFormStep] = useState("sendEmail");
    const [inputs, setInputs] = useState({
        email: '',
        code: '',
        newPassword: '',
        newPasswordConfirmation: ''
    });

    return (
        <Container>
            {formStep === "sendEmail" && <PassRecoverSendEmail inputs={inputs} setInputs={setInputs} setFormStep={setFormStep}/>}
            {formStep === "codeValidation" && <PassRecoverCodeValidation  inputs={inputs} setInputs={setInputs} setFormStep={setFormStep}/>}
            {formStep === "newPassword" && <PassRecoverNewPassword inputs={inputs} setInputs={setInputs} setFormStep={setFormStep}/>}
            {formStep === "confirmation" && <PassRecoverConfirmation />}
        </Container>
    );
};

export default LoginPassRecover;
