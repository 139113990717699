import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useAppLocation = () => {
  const location = useLocation();
  const appLocation = useMemo(() => {
    const currentLocation = location.pathname
      .split('/')
      .filter(it => it.length);

    const [app, module, page, ...rest] = currentLocation;

    return {
      path: location.pathname,
      items: currentLocation,
      app,
      module,
      page,
      rest,
    };
  }, [location]);

  return appLocation;
};

export { useAppLocation };
