import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }
`;

export const Name = styled.div`
  color: #ffffff;
  font-size: 16px;
  margin-right: 15px;
`;

