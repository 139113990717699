import baseUrl from "../utils/baseUrl";
import axios from "./api";

const getNotificationByMechanicId = async (mechanicId) =>
    await axios.get(
            baseUrl + `/mechanic-notification/${mechanicId}`
        ).then((response) => response.data);

const sendNotification = async (body) => {
    return await axios.post(baseUrl + `/mechanic-notification/send`, body).then((response) => response.data);
};

const removeNotification = async () => {
    return await axios.delete(baseUrl + `/mechanic-notification/remove`).then((response) => response.data);
};

export { getNotificationByMechanicId, sendNotification, removeNotification };
