import styled from 'styled-components';

export const FormContainer = styled.div`
    height: 450px;
    width: 450px;

    border-radius: 30px;

    background-color: #f5f5f5;
    
    box-shadow: 2px 0 5px rgba(102,102,102,0.15), 1px 0 4px rgba(102,102,102,0.25);
`;