import styled from "styled-components";
import Card from "@mui/material/Card";

export const Container = styled(Card)`
    padding: 5px;
    max-width: 1500px;
    max-height: 800px;
    color: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #f5f5f5;
`;

export const FormContainer = styled.div`
    display: grid;

    grid-template-columns: 220px 850px;
    grid-template-rows: 75vh;

    gap: 10px;

    grid-template-areas: "CLIENTDETAILS DETAILSTABLE";
`;

export const ClientDetailsContainer = styled(Card)`
    grid-area: "CLIENTDETAILS";
`;

export const DetailsTableContainer = styled(Card)`
    grid-area: "DETAILSTABLE";
    padding: 20px;
    overflow: auto;

    display: grid;

    grid-template-columns: auto;

    grid-template-rows: 62vh 7vh;
    /* 
    @media only screen and (max-width: 600px) {
        grid-template-rows: 50vh 5vh;
    }
    @media only screen and (max-width: 1200px) {
        grid-template-rows: 50vh 5vh;
    }
    @media only screen and (max-width: 1900px) {
        grid-template-rows: 50vh 5vh;
    }
    @media only screen and (max-width: 2000px) {
        grid-template-rows: 65vh 4vh;
    } */

    grid-template-areas:
        "TABLEDETAILS"
        "ADDSALE";
`;

export const TableContainer = styled.div`
    grid-area: "TABLEDETAILS";
    overflow: auto;
`;

export const AddSaleContainer = styled.div`
    grid-area: "ADDSALE";

    padding-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
`;
