import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useModalContext } from "../../../contexts/ModalContext";
import TextField from "@mui/material/TextField";
import { Container, FormContainer } from "./styles";
import "react-data-table-component-extensions/dist/index.css";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import * as UsersService from "../../../services/users";
import ConfirmationModal from "../../../components/ui/ConfirmationModal";

var moment = require("moment");

const UserDetailsModal = ({ data, updateTable }) => {
    const { setModal } = useModalContext();
    const handleClose = () => setModal(null);
    const [confirmationModal, setConfirmationModal] = useState({ open: false, choice: "update" });
    const [inputs, setInputs] = useState({
        id: data.id,
        name: data.name,
        email: data.email,
        birthDate: data.birthDate,
        phone: data.phone,
        role: data.role,
    });

    const handleInputsChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleAutocompleteChange = (event, dataValue) => {
        const name = "role";
        const value = dataValue;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleUpdateUser = async (event) => {
        event.preventDefault();

        const body = {
            name: inputs.name,
            email: inputs.email,
            birthDate: moment(inputs.birthDate).toDate(),
            phone: inputs.phone,
        };

        const roleBody = {
            email: inputs.email,
            roleName: inputs.role == "ADMIN" ? "ROLE_ADMIN_MECHANIC" : "ROLE_USER_MECHANIC",
        };

        await UsersService.updateUser(inputs.id, body)
            .then((x) => {
                UsersService.changeUserRole(roleBody).then((x) => {
                    setModal(null);
                    updateTable();
                    alert(`Usuário atualizado com sucesso!`);
                });
            })
            .catch(() => alert(`Erro ao atualizar este Usuário!`));
    };

    const handleDelete = async () => {
        await UsersService.deleteUser(inputs.id)
            .then((x) => {
                updateTable();
                setModal(null);
                alert(`Usuário deletado com sucesso!`);
            })
            .catch(() => alert(`Erro ao deletar este Usuário!`));
    };

    const onSubmit = (event) => {
        //this will make sure the url will not change
        event.preventDefault();

        //foi um parto achar esse id
        let id = event.nativeEvent.submitter.id;

        if (id === "updateBtn") {
            setConfirmationModal({ open: true, choice: "update" });
        } else if (id === "deleteBtn") {
            setConfirmationModal({ open: true, choice: "delete" });
        } else {
            //do nothing
        }
    };

    return (
        <>
            <Modal open={true} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Container>
                    <Box m={3} fontWeight="bold" color="#666666" fontSize={{ xs: "h7.fontSize", sm: "h7.fontSize", md: "h6.fontSize", xl: "h5.fontSize" }}>
                        Detalhes Usuário
                    </Box>
                    <form onSubmit={onSubmit}>
                        <FormContainer>
                            <TextField value={inputs.name} required name="name" label="Nome Completo" variant="outlined" style={{ width: "100%" }} onChange={handleInputsChange} />
                            <TextField
                                value={inputs.email}
                                required
                                name="email"
                                type="email"
                                label="Email"
                                variant="outlined"
                                style={{ width: "100%" }}
                                onChange={handleInputsChange}
                            />
                            <TextField
                                defaultValue={moment(inputs.birthDate).local().format("YYYY-MM-DD")}
                                required
                                name="birthDate"
                                label="Data de Nascimento"
                                type="date"
                                variant="outlined"
                                style={{ width: "100%" }}
                                onChange={handleInputsChange}
                            />
                            <TextField value={inputs.phone} name="phone" required label="Telefone" variant="outlined" style={{ width: "100%" }} onChange={handleInputsChange} />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={permissions}
                                onChange={(event, value) => handleAutocompleteChange(event, value)}
                                defaultValue={inputs.role}
                                isOptionEqualToValue={(option, value) => option === value}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} required label="Permissão" />}
                                style={{ width: "100%" }}
                            />
                        </FormContainer>
                        <Box display="flex" justifyContent="flex-end" padding="20px" justifySelf="center">
                            <Button
                                id="deleteBtn"
                                variant="outlined"
                                color="error"
                                onClick={() => setConfirmationModal({ open: true, choice: "delete" })}
                                style={{ marginRight: "4px" }}
                            >
                                Deletar
                            </Button>
                            <Button id="updateBtn" variant="contained" color="secondary" type="submit" style={{ marginRight: "4px" }}>
                                Atualizar
                            </Button>
                            <Button variant="contained" onClick={() => handleClose()}>
                                Fechar
                            </Button>
                        </Box>
                    </form>
                </Container>
            </Modal>
            <ConfirmationModal
                open={confirmationModal.open}
                setOpen={setConfirmationModal}
                subText={`Deseja mesmo ${confirmationModal.choice === "update" ? "atualizar" : "deletar"} este usuário?`}
                handleConfirmation={confirmationModal.choice === "update" ? handleUpdateUser : handleDelete}
            />
        </>
    );
};

const permissions = ["ADMIN", "USER"];

export default UserDetailsModal;
