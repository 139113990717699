import styled from "styled-components";
import Card from "@mui/material/Card";
import CurrencyInput from "react-currency-input-field";

export const Container = styled.div`
    margin-left: 10px;
`;

export const FormContainer = styled.div`
    display: grid;
    padding-bottom: 30px;

    grid-template-columns: 25vw auto;
    grid-template-rows: 14vh 65vh 20vh;

    grid-gap: 10px;

    grid-template-areas:
        "CLIENTDETAILS CLIENTDETAILS"
        "PRODUCTLIST DETAILSTABLE"
        "NOTES DETAILSTABLE";
`;

export const ClientDetailsContainer = styled(Card)`
    /* grid-area: 'CLIENTDETAILS'; */
    grid-column: 1 / 3;
`;

export const ProductListContainer = styled(Card)`
    /* grid-area: 'PRODUCTLIST'; */
    grid-column: 1 / 2;
    grid-row: 2 / 3;
`;

export const NotesContainer = styled(Card)`
    /* grid-area: 'NOTES'; */
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    padding: 15px;
`;

export const DetailsTableContainer = styled(Card)`
    /* grid-area: 'DETAILSTABLE'; */
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    padding: 20px;
    overflow: auto;

    display: grid;

    grid-template-columns: auto;

    grid-template-rows: 73vh 7vh;

    grid-template-areas:
        "TABLEDETAILS"
        "ADDSALE";
`;

export const TableContainer = styled.div`
    grid-area: "TABLEDETAILS";
    overflow: auto;
`;

export const AddSaleContainer = styled.div`
    grid-area: "ADDSALE";

    padding-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
`;

export const StyledCurrencyInput = styled(CurrencyInput)`
    width: 100%;
    height: 50px;
    font-size: 15px;
`;
