import React from 'react';
// import { useAuth } from '../../../../hooks/auth';
import { useUser } from '../../../../hooks/user';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Avatar from '@mui/material/Avatar';
import { useAuth } from '../../../../hooks/useAuth';

import {
  Container,
  Name,
} from './styles';

const UserNav = () => {
  const { open, openMenu, closeMenu } = useUser();
  const { loggedUser } = useAuth();

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

  function stringAvatar(name) {
    try{
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container>
      <Name>{loggedUser.name}</Name>
      <Avatar sx={{ width: 35, height: 35 }} {...stringAvatar(loggedUser.name)}/>
      {/* {!open && <OpenMenuButton onClick={openMenu} />}
      {open && <CloseMenuButton onClick={closeMenu} />} */}
      {/* {!open && <IconButton><ArrowDropDownIcon/></IconButton>}
      {open && <IconButton><ArrowDropUpIcon/></IconButton>} */}
      {!open && <IconButton sx={{ marginLeft: 1, color: '#ffffff' }} onClick={openMenu}><ArrowDropDownIcon/></IconButton>}
      {open && <IconButton sx={{ marginLeft: 1, color: '#ffffff' }} onClick={closeMenu}><ArrowDropUpIcon/></IconButton>}
    </Container>
  );
};

export default UserNav;
