import baseUrl from "../utils/baseUrl";
import axios from "./api";

const getClientsByMechanicId = async (mechanicId, page, pageSize, sortBy, sortDirection, filterText) =>
    await axios
        .get(
            baseUrl +
                `/mechanics/clients?mechanicId=${mechanicId}&pageNumber=${page}&pageSize=${pageSize}&sortDirection=${sortDirection}&sortBy=${sortBy}&appUserName=${filterText}`
        )
        .then((response) => response.data);

const addClient = async (mechanicId, uniqueIdentifier) => {
    return await axios.post(baseUrl + `/mechanics/${mechanicId}/clients/save/${uniqueIdentifier}`).then((response) => response.data);
};

const removeClient = async (mechanicId, uniqueIdentifier) => {
    return await axios.delete(baseUrl + `/mechanics/${mechanicId}/clients/remove/${uniqueIdentifier}`).then((response) => response.data);
};

export { getClientsByMechanicId, addClient, removeClient };
