import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Container, CardContainer, ImageContainer } from "./styles";
import Box from "@mui/material/Box";
import { Button, CircularProgress, ImageList, ImageListItem, LinearProgress, Typography } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import * as PromotionsService from "../../services/promotions"
import { useDropzone } from "react-dropzone";
import { baseStyle, acceptStyle, rejectStyle, focusedStyle } from "./dropzone.styles";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ConfirmationModal from "../../components/ui/ConfirmationModal";

const Status = {
    APPROVED: {
        text: 'Aprovado / Em execução',
        color: 'green' 
    },
    PENDING: {
        text: 'Esperando aprovação',
        color: 'blue' 
    },
    DENIED: {
        text: 'Negado',
        color: 'red' 
    },
    NOTSENT: {
        text: 'Esperando cadastro',
        color: 'gray' 
    }
};

const Promotions = () => {
    const { loggedMechanic } = useAuth();
    const [inputs, setInputs] = useState({
        status: Status.NOTSENT,
        reason: "",
        images: [],
    });
    const [ loading, setLoading ] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState({ open: false, choice: "update" });
    
    useEffect(() => {
        fetchData()
    }, [loggedMechanic.id])

    async function fetchData() {
        await PromotionsService.getPromotionByMechanicId(loggedMechanic.id)
        .then((response) => {
            setInputs(() => ({
                status: Status[response.status] ?? Status.NOTSENT,
                reason: response.reason ?? "",
                images: response.images ?? []
            }))
        })
        console.log(inputs.images)
    }

    const onDrop = useCallback((acceptedFiles) => {
        const filesPromises = acceptedFiles.map((file) => {
            return new Promise((resolve) => {
                const reader = new FileReader();
    
                reader.onload = function () {
                    resolve(reader.result);
                };
    
                reader.readAsDataURL(file);
            });
        });
    
        Promise.all(filesPromises).then((fileContents) => {
            setPreview(fileContents);
        });
    }, []);

    const handleSendForApproval = async (event) => {
        event.preventDefault();
        if (acceptedFiles.length > 0) {
            try {
                setLoading(true)
                const formData = new FormData();
    
                // Append each file to the FormData object
                acceptedFiles.forEach((file) => {
                    formData.append('files', file);
                });
    
                // Make a POST request to the server endpoint
                await PromotionsService.sendPromotion(formData)
                    .then(() => {
                        alert(`Promoção enviada para aprovação!`)
                        fetchData()
                        setPreview([])
                        acceptedFiles.splice(0, acceptedFiles.length)
                    })
                    .catch(() => alert(`Erro ao enviar a promoção para aprovação!`));
            } catch (error) {
                // Handle errors
                console.error('Erro ao fazer o upload das imagens: ', error.message);
            }
        }
        setLoading(false)
    };


    const handleRemove = async (event) => {
        event.preventDefault();
        await PromotionsService.removePromotion()
            .then(() => {
                alert(`Promoção removida com sucesso!`)
                fetchData()
                setConfirmationModal({ open: false })
            })
            .catch(() => alert(`Erro ao tentar remover esta promoção!`));
    };
  
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
      } = useDropzone({
        onDrop,     
        accept: {
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': []
        },
        maxFiles: 3
    });

    const [preview, setPreview] = useState([]);
    
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);



    const files = acceptedFiles.map(file => (
      <li key={file.path}>
        <Typography fontSize={14} fontWeight="bold">{file.path} - {file.size  / 1000000} MB</Typography>
      </li>
    ));

    const [totalFilesSize, setTotalFilesSize] = useState(0)

    useEffect(() => {
        let total = 0
        acceptedFiles.map(file => (
            total = total + file.size
        ));
        setTotalFilesSize(total / 1000000) //convert to MB
    }, [acceptedFiles])

    return (
        <Container>
            <Box
                mb={3}
                fontWeight="bold"
                color="#666666"
                fontSize={{
                    xs: "h7.fontSize",
                    sm: "h6.fontSize",
                    md: "h5.fontSize",
                }}
            >
                Promoções
            </Box>
            <CardContainer>
                <Box display="flex" justifyContent="space-between">
                    <Typography fontSize={20} fontWeight="bold">
                        Suas promoções atuais
                    </Typography>
                    {inputs.status !== Status.NOTSENT && <Button variant="outlined" color="error" onClick={() => setConfirmationModal({ open: true })}>
                        Remover Promoção
                    </Button>}
                </Box>
                <Box display="flex" gap={1}>
                    <Typography><b>Status - </b></Typography>
                    <Typography color={inputs.status.color}>{inputs.status.text}</Typography>
                </Box>
                {inputs.reason !== "" && 
                    <Box display="flex" gap={1}>
                        <Typography><b>Motivo - </b></Typography>
                        <Typography color={inputs.status.color}>{inputs.reason}</Typography>
                    </Box>
                }
                {inputs.images.length > 0 && 
                    <>
                        <Typography fontSize={16}>
                            <b>Imagens</b>
                        </Typography>
                        <ImageList sx={{ height: 250, maxWidth: 1100 }} cols={3} rowHeight={9}>
                            {inputs.images.map((item) => (
                                <ImageListItem key={item}>
                                    <img
                                        srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                        alt="Upload preview"
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                        <Typography fontSize={16}>
                            Assim que aprovadas, as imagens acima irão aparecer para os seus clientes na seção do app referente
                            às promoções.
                        </Typography>
                    </>
                }
            </CardContainer>
            <ImageContainer> 
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" flexDirection="column" rowGap={1}>
                        <Typography fontSize={20} fontWeight="bold" color="#111">
                            Mostre suas promoções aos seus clientes!
                        </Typography>
                        <Typography fontSize={16}>
                            Adicione abaixo as imagens que deseja promover
                        </Typography>
                        <div className="dropzone">
                            <div {...getRootProps({style})}>
                                <input {...getInputProps()} />
                                <FileUploadIcon/>
                                <p>ARRASTE E SOLTE ARQUIVOS AQUI, OU CLIQUE PARA SELECIONAR ARQUIVOS</p>
                                <em>(Máximo 3 imagens)</em>
                            </div>
                        </div>
                        <Typography fontSize={14} color="blue">
                            *No máximo 3 imagens
                        </Typography>
                        <Typography fontSize={14} color="blue">
                            *O formato das imagens deve ser JPEG ou PNG
                        </Typography>
                        <Typography fontSize={14} color="blue">
                            *As imagens devem ter a proporção 1:1, de preferência 1080px X 1080px (ex.: Post no instagram)
                        </Typography>
                        <Typography fontSize={14} color="blue">
                            *Tamanho de no máximo 1 MB somando as 3 imagens
                        </Typography>

                        {preview.length > 0 && <>
                            <Typography fontSize={16} fontWeight="bold">Imagens a serem enviadas</Typography>
                            <Box display="flex" flexDirection="column" gap={1}>
                                <ImageList sx={{  height: 250, maxWidth: 1100 }} cols={3} rowHeight={9}>
                                    {preview.map((item) => (
                                        <ImageListItem key={item}>
                                            <img
                                                src={item}
                                                alt="Upload preview"
                                                loading="lazy"
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                                {files.length > 0 && 
                                    <>
                                        <Button
                                            style={{width: "150px"}}
                                            variant="outlined"
                                            onClick={() => {
                                                setPreview([])
                                                acceptedFiles.splice(0, acceptedFiles.length)
                                            }}>
                                            Limpar anexos
                                        </Button>
                                        <ul>{files}</ul>
                                        {totalFilesSize > 1 && 
                                            <>
                                                <Typography color="red" fontWeight="bold">O tamanho total das imagens é maior do que 1MB, diminua o tamanho dos arquivos</Typography>
                                                <Typography color="red" fontWeight="bold">Total: {totalFilesSize} MB</Typography>
                                            </>
                                        }
                                    </>
                                }
                            </Box>
                        </>}
                        {(loading === false) && 
                        <>
                            <Typography fontSize={16}>
                                Revise a ordem das imagens, a qualidade e as informações nelas, após isto,
                                mande para aprovação. Retornaremos em até 2 dias úteis.
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    backgroundColor: "success",
                                    height: 54,
                                    width: 250,
                                    fontWeight: "bold",
                                    boxShadow: "none",
                                    marginTop: "15px"
                                }}
                                onClick={(event) => handleSendForApproval(event)}
                                disabled={preview.length <= 0 || totalFilesSize > 1}
                            >
                                Enviar para Aprovação
                            </Button>
                        </>
                        }
                        {loading === true && 
                            <>
                                <Typography fontSize={16} fontWeight="bold" marginTop="15px" color="blue">
                                    Enviando as imagens para aprovação...
                                </Typography>
                                <LinearProgress style={{ margin: "15px", width: "100%", color: "info" }} />
                            </>
                        }
                    </Box>
                </Box>
                
                
            </ImageContainer>
            <ConfirmationModal
                open={confirmationModal.open}
                setOpen={setConfirmationModal}
                subText={"Deseja mesmo remover esta promoção? Para promover alguma imagem, você terá que submeter para aprovação novamente."}
                handleConfirmation={handleRemove}
            />
            
        </Container>
    );
};

export default Promotions;
