import { Switch, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-content: center;

    background-color: #f5f5f5;
    border-radius: 25px;
    justify-content: space-between;

    width: 100%;
    height: 45px;
`;

export const StyledSwitchTitleContainer = styled.div`
    display: flex;

    justify-content: space-between;
    gap: 3px;

    margin-top: 9px;
    margin-left: 18px;
`;

export const StyledSwitchTitle = styled(Typography)`
    align-content: center;
    margin-left: 26px;
    margin-top: 5px;
    width: 100%;

    &.ant-typography {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
    }
`;

export const StyledSwitch = styled(Switch)`
    margin-top: 2px;
    margin-right: 5px;
`;
