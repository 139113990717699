import styled from "styled-components";
import Card from "@mui/material/Card";

export const FormContainer = styled(Card)`
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    direction: column;
    height: 470px;
    margin: 5px;
    justify-items: space-between;
`;

export const ButtonContainer = styled(Card)`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    direction: column;
    margin: 5px;
    justify-items: space-between;
`;
