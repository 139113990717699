import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Container } from "./styles";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import NewProductServiceModal from "../NewProductServiceModal";
import ProductServiceDetailsModal from "../ProductServiceDetailsModal";
import { useModalContext } from "../../../contexts/ModalContext";
import * as ProductsAndServicesService from "../../../services/productsAndServices";
import { useAuth } from "../../../hooks/useAuth";
import { Typography } from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import AddIcon from "@mui/icons-material/Add";
import FilterComponent from "../../../components/ui/Filter";

const Services = () => {
    const { setModal } = useModalContext();
    const [tableData, setTableData] = useState([]);
    const { loggedMechanic } = useAuth();

    //pagination states
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortDirection, setSortDirection] = useState("ASC");
    const [sortBy, setSortBy] = useState("name");
    const [filterText, setFilterText] = useState("");

    useEffect(() => {
        fetchTableData(); //fetch page 0 of sales
    }, [page, perPage, sortDirection, sortBy, filterText]);

    async function fetchTableData() {
        const response = await ProductsAndServicesService.getProductsAndServicesByMechanicIdPaginated(
            loggedMechanic.id,
            page,
            perPage,
            sortBy,
            sortDirection,
            filterText,
            true //because its service
        );

        setTotalRows(response.totalElements);
        setTableData(response.content);
    }

    const handlePageChange = (newPage) => {
        setPage(newPage - 1);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSortChange = async (column, sortDirection) => {
        setSortBy(column.id);
        setSortDirection(sortDirection.toUpperCase());
    };

    const columns = [
        {
            id: "name",
            name: "Serviço",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Atividade",
            selector: (row) => row.activityName,
        },
        {
            id: "price",
            name: "Preço",
            selector: (row) => new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(row.price),
            sortable: true,
        },
        {
            name: "Descrição",
            selector: (row) => row.description,
        },
        {
            name: "Detalhes",
            cell: (row) => (
                <Button id={row.ID} variant="outlined" onClick={() => setModal(<ProductServiceDetailsModal data={row} updateTable={fetchTableData} />)}>
                    <ManageSearchIcon />
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText("");
            }
        };

        return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder="Filtrar por Serviço" />;
    }, [filterText]);

    return (
        <Container>
            <Box mb={3} fontWeight="bold" color="#666666" fontSize={{ xs: "h7.fontSize", sm: "h6.fontSize", md: "h5.fontSize" }}>
                Gerenciar - Serviços
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Button variant="contained" onClick={(row) => setModal(<NewProductServiceModal updateTable={fetchTableData} />)}>
                    <AddIcon fontSize="small" />
                    Novo Serviço
                </Button>
                <Typography color="#a18e38" fontSize={{ xs: "h7.fontSize", sm: "h6.fontSize", md: "h6.fontSize" }}>
                    Total: <b>{totalRows} Serviços</b>
                </Typography>
            </Box>
            <Box mt={3} mb={5}>
                <Card>
                    <DataTable
                        columns={columns}
                        data={tableData}
                        pagination
                        sortServer
                        onSort={handleSortChange}
                        progressPending={loading}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                    />
                </Card>
            </Box>
        </Container>
    );
};

export default Services;
