import React from "react";

import { FormContainer } from "./styles";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import * as PasswordService from "../../../services/password";
import { Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../../assets/img/OiCarLogoDark.svg";

const useStyles = makeStyles({
    fields: {
        width: 300,
    },
});

const PassRecoverNewPassword = ({ inputs, setInputs, setFormStep }) => {
    const classes = useStyles();

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (inputs.newPassword === inputs.newPasswordConfirmation) {
            PasswordService.changePasswordWithCode(inputs.email, inputs.code, inputs.newPassword)
                .then(() => {
                    setFormStep("confirmation");
                })
                .catch((error) => {
                    if (error.response.status === 500) {
                        alert("Erro ao alterar a senha!");
                    }
                });
        } else {
            alert("As senhas não são iguais!");
        }
    };

    return (
        <FormContainer>
            <form onSubmit={handleSubmit}>
                <Grid container direction="column" rowSpacing={2} justifyContent="center" alignContent="center" marginTop={3}>
                    <Grid item display="grid" justifyItems="center">
                        <Logo style={{ width: "120px", height: "50px" }} />
                    </Grid>
                    <Grid item marginTop={2} justifyItems="center">
                        <Typography fontSize={16} fontWeight="bold">
                            Código validado!
                        </Typography>
                        <Typography fontSize={16}>informe abaixo a nova senha que deseja.</Typography>
                    </Grid>
                    <Grid item marginTop={1}>
                        <TextField name="newPassword" type="password" required label="Nova Senha" variant="outlined" className={classes.fields} onChange={handleChange} />
                    </Grid>
                    <Grid item>
                        <TextField
                            name="newPasswordConfirmation"
                            required
                            label="Confirmação Nova Senha"
                            type="password"
                            variant="outlined"
                            className={classes.fields}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item marginTop={3}>
                        <Button variant="contained" type="submit" style={{ width: 300, height: 50 }}>
                            Confirmar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="text" style={{ width: 300, height: 30 }} onClick={() => setFormStep("sendEmail")}>
                            Voltar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContainer>
    );
};

export default PassRecoverNewPassword;
