import React from "react";

import { SideNavItems } from "../../../assets/SideNavContent";
import SideNavSection from "./SideNavSection";

import { Container } from "./style";

const SideNav = ({}) => {
    return (
        <Container>
            {SideNavItems.modules.map((module) => {
                return <SideNavSection key={module.name} section={module} />;
            })}
        </Container>
    );
};

export default SideNav;
