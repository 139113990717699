import { useState, useEffect, createContext, useContext } from "react";

import { getTokens } from "../services/auth";
import * as UsersService from "../services/users";
import * as MechanicsService from "../services/mechanics";
import * as UserConfigsService from "../services/userConfigs";
import api from "../services/api";
import { Redirect } from "react-router-dom";

const AuthContext = createContext();

function AuthProvider({ children }) {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loggedUser, setLoggedUser] = useState({});
    const [loggedMechanic, setLoggedMechanic] = useState({});
    const [userConfigs, setUserConfigs] = useState({});

    useEffect(() => {
        const token = localStorage.getItem("access_token");

        if (token) {
            api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
            setAuthenticated(true);
            const tokenData = parseJwt(token);
            fetchUserData(tokenData.sub);
        }

        setLoading(false);
    }, []);

    async function handleLogin(username, password) {
        try {
            await getTokens(username, password)
                .then((data) => {
                    localStorage.setItem("access_token", JSON.stringify(data.access_token));
                    localStorage.setItem("refresh_token", JSON.stringify(data.refresh_token));
                    api.defaults.headers.Authorization = `Bearer ${data.access_token}`;

                    fetchUserData(username);
                })
                .catch(() => {
                    alert("Erro ao authenticar, verifique suas credenciais!");
                    handleLogout();
                });

            if (authenticated) {
                return (
                    <Redirect
                        to={{
                            pathname: "/dashboard/registros",
                        }}
                    />
                );
            }
        } catch (error) {
            console.log(error);
            setAuthenticated(false);
            handleLogout();
        }
    }

    function fetchUserData(username) {
        UsersService.getMechanicUserByEmail(username)
            .then(async (userData) => {
                setLoggedUser(userData);
                await MechanicsService.getMechanicByUserId(userData.id).then((mechanic) => {
                    setLoggedMechanic(mechanic);
                    setAuthenticated(true);
                });
                await UserConfigsService.getUserConfigsByUserId(userData.id).then((configs) => {
                    setUserConfigs(configs);
                });
            })
            .catch(() => {
                //TODO: handle logout aqui
                setAuthenticated(false);
                alert("Erro ao autenticar, verifique suas credenciais!");
            });
    }

    function handleLogout() {
        setAuthenticated(false);
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        api.defaults.headers.Authorization = undefined;

        setLoggedUser({});
        setLoggedMechanic({});
        setUserConfigs({});
        return (
            <Redirect
                to={{
                    pathname: "/login",
                }}
            />
        );
    }

    function parseJwt(token) {
        if (!token) {
            return;
        }
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    }

    function handleHideProductsPrice() {
        UserConfigsService.changeHideProducsPricesByUserId(loggedUser.id)
            .then((configs) => {
                setUserConfigs({ ...userConfigs, hideProductServicePrice: !userConfigs.hideProductServicePrice });
                return true;
            })
            .catch(() => {
                return alert(
                    "Erro ao trocar configuração para esconder os preços, entre em contato com o suporte!"
                );
            });
    }

    return (
        <AuthContext.Provider
            value={{
                loading,
                authenticated,
                handleLogin,
                handleLogout,
                loggedUser,
                loggedMechanic,
                setLoggedUser,
                userConfigs,
                handleHideProductsPrice,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within an LocationProvider");
    }

    return context;
}

export { useAuth, AuthProvider };
