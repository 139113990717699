import styled from 'styled-components';
import Card from '@mui/material/Card';

export const Container = styled.div`
    margin-left: 10px;
`;

export const FormContainer = styled(Card)`
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    direction: column;
    height: 230px;
    margin: 20px;
    justify-items: space-between;
`;

export const ButtonContainer = styled(Card)`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    direction: column;
    margin: 20px;
    justify-items: space-between;
`;



