import React from "react";

import { Container } from "./styles";
import Box from "@mui/material/Box";

import img_em_construcao from "../../assets/img/img_em_construcao.png";

const Audit = () => {
    return (
        <Container>
            <Box m={3} fontWeight="bold" color="#666666" fontSize={{ xs: "h7.fontSize", sm: "h7.fontSize", md: "h6.fontSize", xl: "h5.fontSize" }}>
                PRÓXIMA VERSÃO
            </Box>
            <img style={{ width: 600, height: 400 }} alt="contruction" src={img_em_construcao} />
        </Container>
    );
};

export default Audit;
