import React from 'react';

import { Container } from './styles';

const SideNavSectionHeader = ({ pageName, isSelected, icon }) => {
  return (
    <Container className={isSelected ? 'active' : ''}>
      <div style={{ 'margin-right': '8px' }}>
        {icon}
      </div>
      {pageName}
    </Container>
  );
};

export default SideNavSectionHeader;
