import baseUrl from '../utils/baseUrl';
import axios from './api';

const sendEmail = async (email) => {
  return await axios
    .post(baseUrl + `/password/recover/${email}`)
    .then((response) => response.data);
};

const validadeCode = async (email, code) => {
  return await axios
    .post(baseUrl + `/password/recover/${email}/validate/${code}`)
    .then((response) => response.data);
};

const changePasswordWithCode = async (email, code, newPassword) => {
  const changePassBody = {
    email: email,
    code: code,
    newPassword: newPassword
  }

  return await axios
    .post(baseUrl + `/password/recover/change-password`, changePassBody)
    .then((response) => response.data);
};

export {
  sendEmail,
  validadeCode,
  changePasswordWithCode
};