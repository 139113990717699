import React from "react";

import { StyledSwitch, StyledSwitchTitle, StyledSwitchTitleContainer, Container } from "./styles";

const TitleBarWithSwitch = ({ title, withSwitch = true, onSwitchChange, isSwitchChecked }) => {
    return (
        <Container>
            <StyledSwitchTitleContainer>
                <StyledSwitchTitle>{title}</StyledSwitchTitle>
            </StyledSwitchTitleContainer>
            {withSwitch && <StyledSwitch checked={isSwitchChecked} onChange={onSwitchChange} />}
        </Container>
    );
};

export default TitleBarWithSwitch;
