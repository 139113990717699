import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useModalContext } from "../../../contexts/ModalContext";
import TextField from "@mui/material/TextField";
import { Container, FormContainer, StyledCurrencyInput } from "./styles";
import "react-data-table-component-extensions/dist/index.css";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import { useAuth } from "../../../hooks/useAuth";
import * as ActivitiesService from "../../../services/activities";
import * as productsAndServicesService from "../../../services/productsAndServices";
import { Typography } from "@mui/material";

const NewProductServiceModal = ({ isProduct, updateTable }) => {
    const { setModal } = useModalContext();
    const handleClose = () => setModal(null);
    const { loggedMechanic } = useAuth();
    const [activities, setActivities] = useState([]);
    const [inputs, setInputs] = useState({
        activityName: "Serviço em Mecânica",
    });

    useEffect(() => {
        fetchActivityData();
    }, [loggedMechanic]);

    async function fetchActivityData() {
        var act = await ActivitiesService.getActivities();
        setActivities(act);
    }

    const handleInputsChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleCurrencyChange = (value, name) => setInputs((values) => ({ ...values, [name]: value }));

    const handleAutocompleteChange = (event, dataValue) => {
        const name = "activityName";
        const value = dataValue.name;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleAddProductService = async (event) => {
        event.preventDefault();

        const body = {
            name: inputs.name,
            price: inputs.price.replace(",", "."),
            description: inputs.description,
            //true é service, false product
            type: isProduct ? false : true,
            activityName: inputs.activityName,
            mechanicId: loggedMechanic.id,
        };

        await productsAndServicesService
            .createProductAndService(body)
            .then((x) => {
                setModal(null);
                updateTable();
                alert(`${isProduct ? "Produto" : "Serviço"} criado com sucesso!`);
            })
            .catch(() => alert(`Erro ao criar este ${isProduct ? "Produto" : "Serviço"}!`));
    };

    return (
        <Modal open={true} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Container>
                <Box m={3} fontWeight="bold" color="#666666" fontSize={{ xs: "h7.fontSize", sm: "h7.fontSize", md: "h6.fontSize", xl: "h5.fontSize" }}>
                    Novo {isProduct ? "Produto" : "Serviço"}
                </Box>
                <form onSubmit={handleAddProductService}>
                    <FormContainer isProduct={isProduct}>
                        {!isProduct && (
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={activities}
                                getOptionLabel={(option) => option.name}
                                defaultValue={{ id: 8, name: "Serviço em Mecânica" }}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                onChange={(event, value) => handleAutocompleteChange(event, value)}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} required label="Atividade" />}
                                style={{ width: "100%" }}
                            />
                        )}
                        <TextField name="name" label="Nome" required variant="outlined" style={{ width: "100%" }} onChange={handleInputsChange} />
                        <Box display="flex" flexDirection="row" width="100%">
                            <Typography
                                fontWeight="bold"
                                fontSize={{ xs: "h7.fontSize", sm: "h7.fontSize", md: "h7.fontSize", xl: "h6.fontSize" }}
                                color={{ color: "#666666" }}
                                style={{ marginRight: "12px", marginTop: "12px" }}
                            >
                                Preço:{" "}
                            </Typography>
                            <StyledCurrencyInput
                                id="input-price"
                                name="price"
                                required
                                placeholder="0,00"
                                prefix="R$"
                                decimalsLimit={2}
                                onValueChange={(value, name) => handleCurrencyChange(value, name)}
                            />
                        </Box>

                        <TextField name="description" required label="Descrição" multiline rows={5} variant="outlined" style={{ width: "100%" }} onChange={handleInputsChange} />
                    </FormContainer>
                    <Box display="flex" justifyContent="flex-end" padding="20px" justifySelf="center">
                        <Button variant="contained" type="submit" style={{ marginRight: "4px" }}>
                            Adicionar
                        </Button>
                        <Button variant="outlined" onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </Box>
                </form>
            </Container>
        </Modal>
    );
};

export default NewProductServiceModal;
