import React from "react";

import { FormContainer } from "./styles";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import * as PasswordService from "../../../services/password";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { ReactComponent as Logo } from "../../../assets/img/OiCarLogoDark.svg";

const useStyles = makeStyles({
    fields: {
        width: 300,
    },
});

const PassRecoverCodeValidation = ({ inputs, setInputs, setFormStep }) => {
    const classes = useStyles();

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        PasswordService.validadeCode(inputs.email, inputs.code)
            .then(() => {
                setFormStep("newPassword");
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    alert("Código inválido!");
                }
            });
    };

    return (
        <FormContainer>
            <form onSubmit={handleSubmit}>
                <Grid container direction="column" rowSpacing={2} justifyContent="center" alignContent="center" marginTop={3}>
                    <Grid item display="grid" justifyItems="center">
                        <Logo style={{ width: "120px", height: "50px" }} />
                    </Grid>
                    <Grid item marginTop={1} marginLeft={15}>
                        <MarkEmailReadIcon htmlColor="#666666" style={{ height: "50px", width: "50px" }} />
                    </Grid>
                    <Grid item justifyItems="center">
                        <Typography fontSize={16} fontWeight="bold">
                            Enviamos um código para seu email!
                        </Typography>
                        <Typography fontSize={16}>Informe abaixo o código recebido.</Typography>
                    </Grid>
                    <Grid item marginTop={2}>
                        <TextField name="code" required label="Código" variant="outlined" className={classes.fields} onChange={handleChange} />
                    </Grid>
                    <Grid item marginTop={3}>
                        <Button variant="contained" type="submit" style={{ width: 300, height: 50 }}>
                            Validar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="text" style={{ width: 300, height: 30 }} onClick={() => setFormStep("sendEmail")}>
                            Voltar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContainer>
    );
};

export default PassRecoverCodeValidation;
