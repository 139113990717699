import styled from "styled-components";

export const Container = styled.div`
    height: 100vh;
    width: 100vw;

    display: grid;

    justify-items: center;
    align-items: center;

    background-color: #042940;
`;
