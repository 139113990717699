import React, { useState } from "react";

import { Container, FormContainer } from "./styles";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { ReactComponent as Logo } from "../../assets/img/OiCarLogoDark.svg";
import { useAuth } from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    fields: {
        width: 300,
    },
});

const Login = () => {
    const classes = useStyles();
    const { handleLogin } = useAuth();
    const history = useHistory();
    const [inputs, setInputs] = useState({
        email: "",
        password: "",
    });

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await handleLogin(inputs.email, inputs.password);
    };

    return (
        <Container>
            <FormContainer>
                <form onSubmit={handleSubmit}>
                    <Grid
                        container
                        direction="column"
                        rowSpacing={2}
                        justifyContent="center"
                        alignContent="center"
                        marginTop={3}
                    >
                        <Grid item display="grid" justifyItems="center">
                            <Logo style={{ width: "120px", height: "50px" }} />
                            <h3>Mecânica</h3>
                        </Grid>
                        <Grid item>
                            <TextField
                                name="email"
                                type="email"
                                required
                                value={inputs.email}
                                label="E-mail"
                                variant="outlined"
                                className={classes.fields}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                name="password"
                                required
                                value={inputs.password}
                                label="Senha"
                                type="password"
                                variant="outlined"
                                className={classes.fields}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item marginTop={3}>
                            <Button variant="contained" type="submit" style={{ width: 300, height: 50 }}>
                                Entrar
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="text"
                                style={{ width: 300, height: 30 }}
                                onClick={() => history.push("/login/recuperar-senha")}
                            >
                                Esqueci minha senha
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </FormContainer>
        </Container>
    );
};

export default Login;
